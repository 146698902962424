// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Form,
//   Input,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
//   Button,
//   FormFeedback,
// } from "reactstrap";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import {
//   SegmentActivationApiCall,
//   checkModificationStatus,
//   mobileNumberUpdate_new2,
//   modification_profile
// } from "../../helpers/fakebackend_helper";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// //redux
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// import loaderImage from "../../assets/images/loader-icon.png";
// import { getHoldings } from "../../store/actions";
// import { getInvestmentSummary } from "../../store/investedSummary/action";
// import { MOD_EKYC_NEW } from "../../helpers/url_helper";

// const Segments = (props) => {
//   const dispatch = useDispatch();
//   const [pendinfData, sendPendingData] = useState([]);
//   const [active, setActive] = useState(false);
//   const [active2, setActive2] = useState(false);
//   const history = useNavigate();
//   const [modifyKey, setModification] = useState(['derivative', 'currency', 'commodity']);

//   const [eSign, setESign] = useState(false);
//   const [segment, setSegment] = useState([]);
//   const [disableButton, setDisableButton] = useState(0);
//   const { profileDetails } = useSelector((state) => ({
//     profileDetails: state.ProfileDetails,
//   }));
//   const { InvestmentSummary } = useSelector((state) => ({
//     InvestmentSummary: state.InvestmentSummary.InvestmentSummary,
//   }));
//   const generatedKey = JSON.parse(localStorage.getItem('genApiKeyResult'));
//   const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
//   useEffect(() => {
//     dispatch(getInvestmentSummary({ apiKey: generatedKey, code: encryptKey }));
//   }, [dispatch, generatedKey, encryptKey]);
//   console.log(pendinfData, 'pendinfDatapendinfDatapendinfData');


//   console.log(InvestmentSummary, 'InvestmentSummaryInvestmentSummaryInvestmentSummaryInvestmentSummary');
//   let segmentArray = [];
//   const split_string =
//     !isEmpty(profileDetails?.userProfile[0]) &&
//     profileDetails?.userProfile[0]?.CEXCHANGE?.split(",");

//   segmentArray = split_string;
//   // alert(segmentArray);
//   const client_code = localStorage.getItem("client_id");

//   const pp = ["BSE", "NSE", "NSEF", "NSEC", "BSEF", "BSEC", "MCX", "NCDX"];
//   const pp2 = ["NSEF", "NSEC", "BSEF", "BSEC", "MCX", "NCDX"];
//   // const ff = segmentArray.length > 0 && segmentArray.filter((el) => {
//   //   return el.trim() === pp
//   // })
//   const output =
//     segmentArray?.length > 0 &&
//     segmentArray?.filter((obj) => {
//       return pp.indexOf(obj.trim()) !== -1;
//     });

//   const output2 =
//     segmentArray.length > 0 &&
//     segmentArray.filter((obj) => {
//       return pp2.indexOf(obj.trim()) !== -1;
//     });
//   console.log(output2, '2');
//   console.log(output, '1');
//   console.log(segmentArray, 'segmentArray');
//   // console.log(!isEmpty(InvestmentSummary) &&
//   //     !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//   //     +InvestmentSummary.portfoliovalueResult.totalinvested > 10000)
//   useEffect(() => {
//     if (props.activeTab === "3") {

//       checkModificationStatus({
//         client_code: client_code,
//         entry_type: "segment",

//       })
//         .then((response) => {

//           if (response.status === true) {
//             // setESign(true);
//             sendPendingData(response.data.modification);
//           } else {
//             // setESign(false);
//             sendPendingData([]);
//             setSegment([]);
//             setActive(false);
//           }
//           //props.sendData(modalMobile);
//         })
//         .catch((err) => {
//           ////console.log(err);
//         });
//     }
//   }, [props.activeTab, active2]);
//   //console.log(pendinfData);

//   let jjj = [];

//   !isEmpty(pendinfData) &&
//     Object.keys(pendinfData).forEach((key) => {
//       jjj.push(pendinfData[key]);
//     });

//   console.log(jjj, 'jjj');
//   // console.log(!isEmpty(InvestmentSummary) &&
//   // !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//   // +InvestmentSummary.portfoliovalueResult.totalinvested > 10000
//   //   ? true
//   //   : false,)
//   const validation = useFormik({
//     enableReinitialize: true,

//     initialValues: {
//       proof_type: "",
//       proof_file: "",
//       isActiveOne: output2.length > 0 ? true : false,
//       isIncomeRange:
//         !isEmpty(InvestmentSummary) &&
//           !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//           +InvestmentSummary.portfoliovalueResult.totalinvested > 10000
//           ? true
//           : false,
//       //income_range: "",
//       file_Password: "",
//     },

//     /*  validationSchema: Yup.object({
//        proof_type: Yup.string().when(["isActiveOne", "income_range"], {
//          is: (isActiveOne, income_range) => {
//            if (isActiveOne) {
//              return false;
//            } else if (!isActiveOne && income_range) {
//              return false;
//            } else if (!isActiveOne && !income_range) {
//              return true;
//            } else {
//              return true;
//            }
//          },
//          then: Yup.string().required("This field is required"),
//          otherwise: Yup.string(),
//        }),
//        proof_file: Yup.string().when(["isActiveOne", "income_range"], {
//          is: (isActiveOne, income_range) => {
//            if (isActiveOne) {
//              return false;
//            } else if (!isActiveOne && income_range) {
//              return false;
//            } else if (!isActiveOne && !income_range) {
//              return true;
//            } else {
//              return true;
//            }
//          },
//          then: Yup.string()
//            .required("This field is required")
//            .test(
//              "fileType",
//              "Image type should be JPG, PNG, JPEG or PDF",
//              (value) => {
//                if (value !== undefined) {
//                  if (
//                    value.includes("jpg") ||
//                    value.includes("jpeg") ||
//                    value.includes("png") ||
//                    value.includes("pdf")
//                  )
//                    return true;
//                  return false;
//                }
//                return false;
//              }
//            ),

//        }),
//        //income_range: Yup.string().required("This field is required"),
//      }), */
//      onSubmit: (values) => {
//       console.log(values?.file_Password,'hhhhhhhhh');
//       setActive2(false);
//       setDisableButton(1);

//       const formData = new FormData();
//       const client_code = localStorage.getItem("client_id");
//       // const total_invested_holding =
//       //   InvestmentSummary.portfoliovalueResult.totalinvested;

//       // if (total_invested_holding < 10000) {
//         const fileInput = document.getElementById("formFile");
//         const files = fileInput.files;
//         const selectedProofType = document.getElementById("nameInput").value;

//         if (!selectedProofType) {
//           setDisableButton(0);
//           toast.error("Please select proof type", { autoClose: 1500 });
//           return;
//         }

//         if (files.length === 0) {
//           setDisableButton(0);
//           toast.error("Please select proof", { autoClose: 1500 });
//           return;
//         }

//         const fileName = files[0].name;
//         const fileExtension = fileName.split(".").pop().toLowerCase();
//         const validExtensions = ["jpg", "jpeg", "png", "pdf"];

//         if (!validExtensions.includes(fileExtension)) {
//           setDisableButton(0);
//           toast.error(
//             "Please select a valid file with extensions: png, jpg, JPEG, or pdf",
//             { autoClose: 1500 }
//           );
//           return;
//         }

//         formData.append("doc_path", files[0]);
//       // }

//       const segmentUpdates = {
//         segment_1: segment[0],
//         segment_2: segment[1],
//         segment_3: segment[2],
//         segment_4: segment[3],
//         pan_no: profileDetails?.userProfile[0]?.PANGIR || "",
//         client_code: client_code,
//         segment_total: segment.length,
//       };

//       Object.keys(segmentUpdates).forEach((key) => {
//         if (segmentUpdates[key] === undefined) {
//           delete segmentUpdates[key];
//         }
//       });

//       const modifyKeys = segment.map((seg) =>
//         seg.includes("NSEF,BSEF")
//           ? "derivative"
//           : seg.includes("NSEC,BSEC")
//           ? "currency"
//           : seg.includes("MCX,NCDX")
//           ? "commodity"
//           : ""
//       );

//       formData.append("client_code", client_code);
//       formData.append("entry_type", "segment");
//       formData.append("old[derivative]", null);
//       formData.append("old[currency]", null);
//       formData.append("old[commodity]", null);
//       formData.append(
//         "new[derivative]",
//         segmentUpdates.segment_1 ? segmentUpdates.segment_1 : null
//       );
//       formData.append(
//         "new[currency]",
//         segmentUpdates.segment_2 ? segmentUpdates.segment_2 : null
//       );
//       formData.append(
//         "new[commodity]",
//         segmentUpdates.segment_3 ? segmentUpdates.segment_3 : null
//       );
//       modifyKeys.forEach((key) => formData.append("modify_key[]", key));
//       formData.append("doc_type", "Income_Proof");
//       formData.append("proof_type", values.proof_type);
//       formData.append("doc_password",values?.file_Password || null);

//       axios
//         .post(MOD_EKYC_NEW + "/update-modification", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: "Bearer your_token",
//           },
//         })
//         .then((response) => {
//           if (response.status) {
//             toast.success("Your modification request was successful.", {
//               autoClose: 1500,
//             });
//             setActive(false);
//             setActive2(true);
//             setSegment([]);
//           } else {
//             toast.error(response.message, { autoClose: 1500 });
//             setActive(false);
//             setSegment([]);
//           }
//         })
//         .catch((error) => {
//           setDisableButton(0);
//           console.error("Error during axios post:", error);
//           toast.error("An error occurred during the update process.", {
//             autoClose: 1500,
//           });
//         });
//     },
//   });


//   const sameFunction = (event) => {
//     if (pendinfData.length === 0) {
//       const value = event.target.value;
//       const isChecked = event.target.checked;

//       if (isChecked) {
//         setSegment((prevSegment) => [...new Set([...prevSegment, value])]);
//       } else {
//         setSegment((prevSegment) =>
//           prevSegment.filter((segment) => segment !== value)
//         );
//       }
//       setActive(isChecked);
//     } else {
//       toast.error("You have already requested for segment updation.");

//     }

//   };





//   const checkDerivative = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('NSEF') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('BSEF'))
//   const check2Derivative = pendinfData?.some(item => item?.modify_key === "derivative")


//   const checkCurrency = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('NSEC') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('BSEC'))
//   const check2Currency = pendinfData?.some(item => item?.modify_key === "currency")

//   const checkCommodity = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('MCX') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('NCDX'))
//   const check2Commodity = pendinfData?.some(item => item?.modify_key === "commodity")



//   return (
//     <>
//       <Row>
//         <Col className="profile-main-head">
//           <div className="d-flex align-items-center justify-content-between mb-3">
//             <div>
//               <h4 className="text-9 text-color5 m-0">Segments</h4>
//               {/* <p className="text-color2 text-15 m-0">
//                 Manage your Segments related details here.
//               </p> */}
//             </div>
//           </div>
//         </Col>
//       </Row>
//       <Row className="mt-2 mb-3">
//         <Col lg={3}>
//           <h4 className="py-2 m-0 text-101 text-color1">Segment Activation</h4>
//         </Col>
//         <Col lg={5} className="additional-switch mt-2">
//           <p className="text-color8 text-11">
//             Activate or deactivate trading segments on your account. Once a
//             segment is deactivated, it cannot be activated for the next 12
//             hours.
//             {/* <span className="learn-more">Learn More</span> */}
//           </p>
//         </Col>
//       </Row>
//       <Row className="mb-3">
//         <Col lg={3}> </Col>
//         <Col lg={5}>
//           <Row className="mb-3">
//             <Col lg={9} xs={9} className="additional-transactions">
//               <Label
//                 className="form-check-label text-4 text-color1"
//                 for="formCheck17"
//               >
//                 Equity Cash - BSE, NSE
//               </Label>
//             </Col>

//             <Col lg={3} xs={3} className="additional-transactions">
//               <p className="text-5 text-color66 m-0">Active</p>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col lg={9} xs={9} className="additional-transactions">
//               <Label
//                 className="form-check-label text-4 text-color1"
//                 for="formCheck16"
//               >
//                 Equity Derivative - NSEF, BSEF
//               </Label>
//             </Col>
//             <Col lg={3} xs={3} className="additional-transactions">
//               {
//                 checkDerivative == true
//                   ? (
//                     <p className="text-5 text-color66 m-0">Active</p>
//                   ) :
//                   check2Derivative == true ?
//                     <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
//                       <p className="text-5 text-color10 m-0"
//                         onClick={() =>
//                           toast.info(
//                             "Already we have one request for segment update",
//                             {
//                               autoClose: 1500,
//                             }
//                           )
//                         }
//                       >Pending</p>

//                     </div>
//                     :
//                     <div className="form-check form-switch">

//                       <input
//                         className="form-check-input"
//                         type="checkbox"
//                         id="formCheck16"
//                         value="NSEF,BSEF"
//                         onChange={(event) => sameFunction(event)}
//                         checked={segment?.find(
//                           (el) => el === "NSEF,BSEF"
//                         )}
//                       //checked={checked.nse}
//                       />
//                     </div>



//               }
//             </Col>
//           </Row>


//           <Row className="mb-3">
//             <Col lg={9} xs={9} className="additional-transactions">
//               <Label
//                 className="form-check-label text-4 text-color1"
//                 for="formCheck16"
//               >
//                 Currency Derivative - NSEC, BSEC
//               </Label>
//             </Col>
//             <Col lg={3} xs={3} className="additional-transactions">
//               {
//                 checkCurrency == true
//                   ? (
//                     <p className="text-5 text-color66 m-0">Active</p>
//                   ) :
//                   check2Currency == true ?
//                     <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
//                       <p className="text-5 text-color10 m-0"
//                         onClick={() =>
//                           toast.info(
//                             "Already we have one request for segment update",
//                             {
//                               autoClose: 1500,
//                             }
//                           )
//                         }
//                       >Pending</p>

//                     </div>
//                     :
//                     <div className="form-check form-switch">
//                       <input
//                         className="form-check-input"
//                         type="checkbox"
//                         id="formCheck15"
//                         value="NSEC,BSEC"
//                         onChange={(event) => sameFunction(event)}
//                         checked={segment.find(
//                           (el) => el === "NSEC,BSEC"
//                         )}
//                       //checked={checked.nse}
//                       />
//                     </div>



//               }
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col lg={9} xs={9} className="additional-transactions">
//               <Label
//                 className="form-check-label text-4 text-color1"
//                 for="formCheck16"
//               >
//                 Commodity Derivative- MCX, NCDX
//               </Label>
//             </Col>
//             <Col lg={3} xs={3} className="additional-transactions">
//               {
//                 checkCommodity == true
//                   ? (
//                     <p className="text-5 text-color66 m-0">Active</p>
//                   ) :
//                   check2Commodity == true ?
//                     <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
//                       <p className="text-5 text-color10 m-0"
//                         onClick={() =>
//                           toast.info(
//                             "Already we have one request for segment update",
//                             {
//                               autoClose: 1500,
//                             }
//                           )
//                         }
//                       >Pending</p>

//                     </div>
//                     :
//                     <div className="form-check form-switch">
//                       <input
//                         className="form-check-input"
//                         type="checkbox"
//                         id="formCheck14"
//                         value="MCX,NCDX"
//                         onChange={(event) => sameFunction(event)}
//                         checked={segment.find(
//                           (el) => el === "MCX,NCDX"
//                         )}
//                       />
//                     </div>
//  }
//             </Col>
//           </Row>

//           {segment.length > 0 && (
//             <Form
//               onSubmit={(values) => {
//                 console.log(values)
//                 validation.handleSubmit(values);
//               }}
//               action="/auth-signin-basic"
//             >
//               <Row>
//                 {/* <p className="text-11 text-color8 p-0 mb-2">
//                   Requires proof for Currency Exchange
//                 </p> */}
//                 {/* <p className="text-11 text-color1 p-0 mb-2">Proof Type</p> */}
//                 {output2.length > 0 ? null : output2.length === 0 && !isEmpty(InvestmentSummary) &&
//                   !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//                   +InvestmentSummary.portfoliovalueResult.totalinvested > 10000 ? null : <Input
//                     className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                     id="nameInput"
//                     type="select"
//                     name="proof_type"
//                     value={validation.values.proof_type}
//                     onBlur={validation.handleBlur}
//                     onChange={validation.handleChange}
//                     invalid={
//                       validation.errors.proof_type &&
//                         validation.touched.proof_type
//                         ? true
//                         : false
//                     }
//                   >
//                   <option key="0" value="" disabled>
//                     Select proof Type
//                   </option>
//                   <option key="1" value="ITR Copy">
//                     ITR Copy
//                   </option>
//                   <option key="2" value="Audited Balancesheet">
//                     Audited balance sheet
//                   </option>
//                   <option key="3" value="Form 16">
//                     Form 16
//                   </option>
//                   <option key="4" value="Latest net worth certificate">
//                     Latest net worth certificate
//                   </option>
//                   <option key="5" value="Last Month's Salary Slip">
//                     Last month's salary slip
//                   </option>
//                   <option
//                     key="6"
//                     value="Bank Account Statement for last 6 months"
//                   >
//                     Bank account statement for last 6 months
//                   </option>
//                   <option key="7" value="Demat Holding Statement">
//                     Demat holding statement
//                   </option>
//                   <option key="8" value="Other investment Proof">
//                     Other investment proof
//                   </option>
//                 </Input>}
//                 {validation.errors.proof_type &&
//                   validation.touched.proof_type ? (
//                   <FormFeedback type="invalid">
//                     {validation.errors.proof_type}
//                   </FormFeedback>
//                 ) : null}
//                 {output2.length > 0 ? null : output2.length === 0 && !isEmpty(InvestmentSummary) &&
//                   !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//                   +InvestmentSummary.portfoliovalueResult.totalinvested > 10000 ? null : <Input
//                   className="nominee-form-input text-color1 text-4 mt-3"
//                   type="file"
//                   id="formFile"
//                   name="proof_file"
//                   value={validation.values.proof_file}
//                   onBlur={validation.handleBlur}
//                   onChange={validation.handleChange}
//                   invalid={
//                     validation.errors.proof_file &&
//                       validation.touched.proof_file
//                       ? true
//                       : false
//                   }
//                 />}
//                 {validation.errors.proof_file &&
//                   validation.touched.proof_file ? (
//                   <FormFeedback type="invalid">
//                     {validation.errors.proof_file}
//                   </FormFeedback>
//                 ) : null}
//                 {/* <Input
//                   type="number"
//                   className="nominee-form-input text-color1 text-4 mt-3"
//                   id="nameInput"
//                   placeholder="Enter Income Range"
//                   name="income_range"
//                   value={validation.values.income_range}
//                   onBlur={validation.handleBlur}
//                   onChange={validation.handleChange}
//                   invalid={
//                     validation.errors.income_range &&
//                     validation.touched.income_range
//                       ? true
//                       : false
//                   }
//                 />
//                 {validation.errors.income_range &&
//                 validation.touched.income_range ? (
//                   <FormFeedback type="invalid">
//                     {validation.errors.income_range}
//                   </FormFeedback>
//                 ) : null} */}
//                 {output2.length > 0 ? null : output2.length === 0 && !isEmpty(InvestmentSummary) &&
//                   !isEmpty(InvestmentSummary.portfoliovalueResult) &&
//                   +InvestmentSummary.portfoliovalueResult.totalinvested > 10000 ? null : 
//                   <Input
//                   type="text"
//                   className="nominee-form-input text-color1 text-4 mt-3"
//                   id="nameInput"
//                   placeholder="Enter File Password"
//                   name="file_Password"
//                   maxLength={20}
//                   value={validation.values.file_Password}
//                   onBlur={validation.handleBlur}
//                   onChange={validation.handleChange}
//                   invalid={
//                     validation.errors.file_Password &&
//                       validation.touched.file_Password
//                       ? true
//                       : false
//                   }
//                 />}
//                 {validation.errors.file_Password &&
//                   validation.touched.file_Password ? (
//                   <FormFeedback type="invalid">
//                     {validation.errors.file_Password}
//                   </FormFeedback>
//                 ) : null}
//                 <div className="d-flex mb-3 p-0 mt-3">
//                   <button
//                     className="btn btn-primary me-3 additional-page-button-save"
//                     type="submit"
//                     disabled={disableButton == 0 ? false : true}
//                   //id="password-addon"
//                   //onClick={() => setPasswordShow(!passwordShow)}
//                   >

//                     {disableButton == 0 ? 'Save' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
//                   </button>
//                   <button
//                     className="additional-page-button-cancel me-2"
//                     type="button"
//                     //id="password-addon"
//                     onClick={() => {
//                       setActive(false);
//                       setSegment([]);
//                     }}
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </Row>
//             </Form>
//           )}
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default Segments;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SegmentActivationApiCall,
  checkModificationStatus,
  mobileNumberUpdate_new2,
  modification_profile,
  sendMobile_new,
  verifyOtpNew
} from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import loaderImage from "../../assets/images/loader-icon.png";
import { getHoldings } from "../../store/actions";
import { getInvestmentSummary } from "../../store/investedSummary/action";
import { MOD_EKYC_NEW } from "../../helpers/url_helper";
import Loader from "../../Components/Common/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Segments = (props) => {
  const dispatch = useDispatch();
  const [pendinfData, sendPendingData] = useState([]);
  const [active, setActive] = useState(false);
  // const [active2, setActive2] = useState(false);
  const history = useNavigate();
  const [modifyKey, setModification] = useState(['derivative', 'currency', 'commodity']);

  const [otp, setOtp] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [details, SecurityDetails] = useState()
  const [segment, setSegment] = useState([]);
  const [disableButton, setDisableButton] = useState(0);
  const [apiSuccess, setApiSuccess] = useState(false);

  const { InvestmentSummary } = useSelector((state) => ({
    InvestmentSummary: state.InvestmentSummary.InvestmentSummary,
  }));
  const generatedKey = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  useEffect(() => {
    dispatch(getInvestmentSummary({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch, generatedKey, encryptKey]);
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));

  const [loading, setLoading] = useState(false)

  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Ensure only numbers are entered
    if (/^\d*$/.test(value)) {
      setOtpValue(value);
    }
  };

  // console.log(InvestmentSummary, 'InvestmentSummaryInvestmentSummaryInvestmentSummaryInvestmentSummary');
  let segmentArray = [];
  const split_string =
    !isEmpty(profileDetails?.userProfile[0]) &&
    profileDetails?.userProfile[0]?.CEXCHANGE?.split(",");

  segmentArray = split_string;
  // alert(segmentArray);
  const client_code = localStorage.getItem("client_id");

  const pp = ["BSE", "NSE", "NSEF", "NSEC", "BSEF", "BSEC", "MCX", "NCDX"];
  const pp2 = ["NSEF", "NSEC", "BSEF", "BSEC", "MCX", "NCDX"];
  // const ff = segmentArray.length > 0 && segmentArray.filter((el) => {
  //   return el.trim() === pp
  // })
  const output =
    segmentArray?.length > 0 &&
    segmentArray?.filter((obj) => {
      return pp.indexOf(obj.trim()) !== -1;
    });

  const output2 =
    segmentArray?.length > 0 &&
    segmentArray?.filter((obj) => {
      return pp2.indexOf(obj.trim()) !== -1;
    });
  console.log(output2, '2');
  console.log(output, '1');
  console.log(segmentArray, 'segmentArray');
  // console.log(!isEmpty(InvestmentSummary) &&
  //     !isEmpty(InvestmentSummary.portfoliovalueResult) &&
  //     +InvestmentSummary.portfoliovalueResult.totalinvested > 10000)
  useEffect(() => {
    setLoading(true)
    setApiSuccess(false)
    if (props.activeTab === "3") {

      checkModificationStatus({
        client_code: client_code,
        entry_type: "segment",

      })
        .then((response) => {
          setLoading(false)

          if (response.status === true) {
            // setESign(true);
            sendPendingData(response.data.modification);
          } else {
            // setESign(false);
            sendPendingData([]);
            setSegment([]);
            setActive(false);
          }
          //props.sendData(modalMobile);
        })
        .catch((err) => {
          setLoading(false)

          ////console.log(err);
        });
    }
  }, [props.activeTab, apiSuccess]);


  let jjj = [];

  !isEmpty(pendinfData) &&
    Object.keys(pendinfData).forEach((key) => {
      jjj.push(pendinfData[key]);
    });


  // console.log(!isEmpty(InvestmentSummary) &&
  // !isEmpty(InvestmentSummary.portfoliovalueResult) &&
  // +InvestmentSummary.portfoliovalueResult.totalinvested > 10000
  //   ? true
  //   : false,)
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      proof_type: "",
      proof_file: "",
      isActiveOne: output2?.length > 0 ? true : false,
      isIncomeRange:
        !isEmpty(InvestmentSummary) &&
          !isEmpty(InvestmentSummary.portfoliovalueResult) &&
          +InvestmentSummary.portfoliovalueResult.totalinvested > 10000
          ? true
          : false,
      //income_range: "",
      file_Password: "",
    },

    /*  validationSchema: Yup.object({
       proof_type: Yup.string().when(["isActiveOne", "income_range"], {
         is: (isActiveOne, income_range) => {
           if (isActiveOne) {
             return false;
           } else if (!isActiveOne && income_range) {
             return false;
           } else if (!isActiveOne && !income_range) {
             return true;
           } else {
             return true;
           }
         },
         then: Yup.string().required("This field is required"),
         otherwise: Yup.string(),
       }),
       proof_file: Yup.string().when(["isActiveOne", "income_range"], {
         is: (isActiveOne, income_range) => {
           if (isActiveOne) {
             return false;
           } else if (!isActiveOne && income_range) {
             return false;
           } else if (!isActiveOne && !income_range) {
             return true;
           } else {
             return true;
           }
         },
         then: Yup.string()
           .required("This field is required")
           .test(
             "fileType",
             "Image type should be JPG, PNG, JPEG or PDF",
             (value) => {
               if (value !== undefined) {
                 if (
                   value.includes("jpg") ||
                   value.includes("jpeg") ||
                   value.includes("png") ||
                   value.includes("pdf")
                 )
                   return true;
                 return false;
               }
               return false;
             }
           ),
 
       }),
       //income_range: Yup.string().required("This field is required"),
     }), */
    onSubmit: (values) => {
      setOtp(false)

      const fileInput = document.getElementById("formFile");
      const files = fileInput.files;
      const selectedProofType = document.getElementById("nameInput").value;

      if (!selectedProofType) {
        setDisableButton(0);
        toast.error("Please select proof type", { autoClose: 1500 });
        return;
      }

      if (files.length === 0) {
        setDisableButton(0);
        toast.error("Please select proof", { autoClose: 1500 });
        return;
      }

      const fileName = files[0].name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const validExtensions = ["jpg", "jpeg", "png", "pdf"];

      if (!validExtensions.includes(fileExtension)) {
        setDisableButton(0);
        toast.error(
          "Please select a valid file with extensions: png, jpg, JPEG, or pdf",
          { autoClose: 1500 }
        );
        return;
      }

      const maxSizeInMB = 2;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (files[0].size > maxSizeInBytes) {
        setDisableButton(0);
        toast.error("File size should not exceed 2MB", { autoClose: 1500 });
        return;
      }
      const mailcheck = {
        mobile: profileDetails.userProfile[0].MOBILE.trim(),
        client_code: client_code,
        entry_type:'segment'
      };

      sendMobile_new(mailcheck)
        .then((response) => {
          if (response.status === true) {
            setOtp(true)
            SecurityDetails(values)

          } else {
            toast('something went wrong')

          }

        }).catch(() => {
          toast('something went wrong')

        })
    },
  });


  const resendOtp=()=>{
    const mailcheck = {
      mobile: profileDetails.userProfile[0].MOBILE.trim(),
      client_code: client_code,
      entry_type:'segment'
    };

    sendMobile_new(mailcheck)
      .then((response) => {
        if (response.status === true) {
         toast.success(response?.message)
        } else {
          toast('something went wrong')

        }

      }).catch(() => {
        toast('something went wrong')

      })
  }

  const incomeammount =profileDetails?.userProfile[0]?.NINCOME

  
  const sameFunction = (event) => {
    if(incomeammount <= 0){
      toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
      return
    }
    if (pendinfData.length === 0) {
      const value = event.target.value;
      const isChecked = event.target.checked;

      if (isChecked) {
        setSegment((prevSegment) => [...new Set([...prevSegment, value])]);
      } else {
        setSegment((prevSegment) =>
          prevSegment.filter((segment) => segment !== value)
        );
      }
      setActive(isChecked);
    } else {
      toast.error("You have already requested for segment updation.");

    }

  };





  const checkDerivative = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('NSEF') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('BSEF'))
  const check2Derivative = pendinfData?.some(item => item?.modify_key === "derivative")
  const Processdervative = pendinfData?.some(item => item?.modify_key === "derivative" && item.is_send_to_ld === 1)


  const checkCurrency = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('NSEC') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('BSEC'))
  const check2Currency = pendinfData?.some(item => item?.modify_key === "currency")
  const processCuruncy = pendinfData?.some(item => item?.modify_key === "currency" && item.is_send_to_ld === 1)

  const checkCommodity = (profileDetails?.userProfile[0]?.CEXCHANGE.includes('MCX') || profileDetails?.userProfile[0]?.CEXCHANGE.includes('NCDX'))
  const check2Commodity = pendinfData?.some(item => item?.modify_key === "commodity")
  const ProcessComi = pendinfData?.some(item => item?.modify_key === "commodity" && item.is_send_to_ld === 1)
  const togalechange = () => {
    setOtp(!otp)
    setOtpValue('')
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const client_code = localStorage.getItem("client_id");
    const client_token = localStorage.getItem("client_token");
    const client_type = localStorage.getItem("client_type");
    const fileInput = document.getElementById("formFile");
    const files = fileInput.files;

    if (otpValue.length === 0) {
      // Handle empty OTP input
      toast('Please enter OTP');
      return;
    }
    setDisableButton(1);
    const newValue = {

      client_code: client_code,
      mobile: profileDetails.userProfile[0].MOBILE.trim(),
      otp: otpValue,
    };
    verifyOtpNew(newValue)
      .then((response) => {
        if (response.status) {
          formData.append("doc_path", files[0]);
          // }

          const segmentUpdates = {
            segment_1: segment[0],
            segment_2: segment[1],
            segment_3: segment[2],
            segment_4: segment[3],
            pan_no: profileDetails?.userProfile[0]?.PANGIR || "",
            client_code: client_code,
            segment_total: segment.length,
          };

          Object.keys(segmentUpdates).forEach((key) => {
            if (segmentUpdates[key] === undefined) {
              delete segmentUpdates[key];
            }
          });

          const modifyKeys = segment.map((seg) =>
            seg.includes("NSEF,BSEF")
              ? "derivative"
              : seg.includes("NSEC,BSEC")
                ? "currency"
                : seg.includes("MCX,NCDX")
                  ? "commodity"
                  : ""
          );

          formData.append("client_code", client_code);
          formData.append("client_token", client_token);
          formData.append('client_type', client_type);
          formData.append("entry_type", "segment");
          formData.append("old[derivative]", '');
          formData.append("old[currency]", '');
          formData.append("old[commodity]", '');

          // modifyKeys.forEach((key) => formData.append("modify_key[]", key));
          modifyKeys.forEach((item, index) => {

            if (item == 'derivative') {
              formData.append("modify_key[]", item);
              formData.append(
                "new[derivative]",
                'NSEF,BSEF'
              );
            }
            if (item == 'currency') {
              formData.append("modify_key[]", item);
              formData.append(
                "new[currency]",
                'NSEC,BSEC'
              );
            }
            if (item == 'commodity') {
              formData.append("modify_key[]", item);
              formData.append(
                "new[commodity]",
                'MCX,NCDX'
              );
            }
          });
          formData.append("doc_type", "Income_Proof");
          formData.append("proof_type", details.proof_type);
          formData.append("doc_password", details?.file_Password || '');

          axios
            .post(MOD_EKYC_NEW + "/update-modification", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer your_token",
              },
            })
            .then((response) => {

              if (response.status) {
                setDisableButton(0);
                setOtp(false)
                toast.success("Your modification request has been sent successfully", {
                  autoClose: 1500,
                });
                // setActive(false);
                setApiSuccess(true)
                setSegment([]);
              } else {
                setDisableButton(0);
                toast.error(response.message, { autoClose: 1500 });

                setSegment([]);
              }
            })
            .catch((error) => {
              setDisableButton(0);
              console.error("Error during axios post:", error);
              toast.error("An error occurred during the update process.", {
                autoClose: 1500,
              });
            });
        }
        else {
          toast(response?.message)
          setDisableButton(0);
        }

      })
      .catch((error) => {
        toast(error?.message)
        setDisableButton(0);
      });
  };

  const mobileNumber = profileDetails?.userProfile[0]?.MOBILE?.trim();
  const maskedNumber = `XXXXXX${mobileNumber?.slice(-4)}`;

  if (loading) {
    return <Loader />
  }

  return (
    <>

      <Modal isOpen={otp} toggle={togalechange} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
        <ModalHeader style={{ textAlign: 'center', marginBottom: '20px' }} toggle={togalechange}>
          OTP Verification
        </ModalHeader>
        <div>
          <h4 className="text-11 text-color8 text-center">
            You will receive OTP on your mobile number {maskedNumber}
          </h4>
        </div>
        <Row className="mt-4">
          <Col lg={11}>
            {/* <form> */}
            <div className="input-group otp-box-input justify-content-center">
              <Input

                className="form-control modal-input-element otp-box"
                placeholder="Enter 6 Digit OTP"
                name="mobile_otp"


                maxLength={6}
                type="number"
                value={otpValue}
                onChange={handleOtpChange}
              />
              <button
                className="btn btn-primary modal-input-button"
                type="button"
                disabled={disableButton == 0 ? false : true}
                onClick={handleSubmit}

              >

                {disableButton == 0 ? 'Verify' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
              </button>
            </div>


            <h4 className="text-11 text-color8 mt-3 text-center mb-2">
              Didn’t receive OTP?{" "}
              {
                (
                  <button
                    className="ms-1 resend-button1"
                    type="button"
                    onClick={resendOtp}

                  >
                    Resend OTP
                  </button>
                )}

            </h4>

          </Col>
        </Row>
        {/* <ModalBody>
          <Input
            type="number"
            value={otpValue}
            onChange={handleOtpChange}
            placeholder="Enter OTP"
            maxLength="6" // Adjust according to your OTP length

          />
        </ModalBody>
        <ModalFooter>
          
          <button
            className="btn btn-primary me-3 additional-page-button-save"
            onClick={handleSubmit}
            disabled={disableButton == 0 ? false : true}
          >

            {disableButton == 0 ? 'Comfirm otp' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
          </button>

        </ModalFooter> */}
      </Modal>
      <Row>
        <Col className="profile-main-head">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <h4 className="text-9 text-color5 m-0">Segments</h4>
              {/* <p className="text-color2 text-15 m-0">
                Manage your Segments related details here.
              </p> */}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mb-3">
        <Col lg={3}>
          <h4 className="py-2 m-0 text-101 text-color1">Segment Activation</h4>
        </Col>
        <Col lg={5} className="additional-switch mt-2">
          <p className="text-color8 text-11">
            Activate or deactivate trading segments on your account. Once a
            segment is deactivated, it cannot be activated for the next 12
            hours.
            {/* <span className="learn-more">Learn More</span> */}
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={3}> </Col>
        <Col lg={5}>
          <Row className="mb-3">
            <Col lg={9} xs={9} className="additional-transactions">
              <Label
                className="form-check-label text-4 text-color1"
                for="formCheck17"
              >
                Equity Cash - BSE, NSE
              </Label>
            </Col>

            <Col lg={3} xs={3} className="additional-transactions">
              <p className="text-5 text-color66 m-0">Active</p>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={9} xs={9} className="additional-transactions">
              <Label
                className="form-check-label text-4 text-color1"
                for="formCheck16"
              >
                Equity Derivative - NSEF, BSEF
              </Label>
            </Col>
            <Col lg={3} xs={3} className="additional-transactions">
              {
                checkDerivative == true
                  ? (
                    <p className="text-5 text-color66 m-0">Active</p>
                  ) :
                  Processdervative ?
                    <p style={{ color: 'green', width: '200px' }}>In progress</p>
                    :
                    check2Derivative == true ?
                      <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
                        <p className="text-5 text-color10 m-0"

                        >Pending</p>

                      </div>
                      :
                      <div className="form-check form-switch">

                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck16"
                          value="NSEF,BSEF"
                          onChange={(event) => sameFunction(event)}
                          checked={segment?.find(
                            (el) => el === "NSEF,BSEF"
                          )}
                        //checked={checked.nse}
                        />
                      </div>



              }
            </Col>
          </Row>


          <Row className="mb-3">
            <Col lg={9} xs={9} className="additional-transactions">
              <Label
                className="form-check-label text-4 text-color1"
                for="formCheck16"
              >
                Currency Derivative - NSEC, BSEC
              </Label>
            </Col>
            <Col lg={3} xs={3} className="additional-transactions">
              {
                checkCurrency == true
                  ? (
                    <p className="text-5 text-color66 m-0">Active</p>
                  ) :
                  processCuruncy ?
                    <p style={{ color: 'green', width: '200px' }}>In progress</p>
                    :
                    check2Currency == true ?
                      <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
                        <p className="text-5 text-color10 m-0"

                        >Pending</p>

                      </div>
                      :
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck15"
                          value="NSEC,BSEC"
                          onChange={(event) => sameFunction(event)}
                          checked={segment.find(
                            (el) => el === "NSEC,BSEC"
                          )}
                        //checked={checked.nse}
                        />
                      </div>



              }
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={9} xs={9} className="additional-transactions">
              <Label
                className="form-check-label text-4 text-color1"
                for="formCheck16"
              >
                Commodity Derivative- MCX, NCDX
              </Label>
            </Col>
            <Col lg={3} xs={3} className="additional-transactions">
              {
                checkCommodity == true
                  ? (
                    <p className="text-5 text-color66 m-0">Active</p>
                  ) :
                  ProcessComi ?
                    <p style={{ color: 'green', width: '200px' }}>In progress</p>
                    :
                    check2Commodity == true ?
                      <div className="form-check form-switch px-0" style={{ cursor: 'pointer' }}>
                        <p className="text-5 text-color10 m-0"

                        >Pending</p>

                      </div>
                      :
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck14"
                          value="MCX,NCDX"
                          onChange={(event) => sameFunction(event)}
                          checked={segment.find(
                            (el) => el === "MCX,NCDX"
                          )}
                        />
                      </div>
              }
            </Col>
          </Row>

          {segment?.length > 0 && (
            <Form
              onSubmit={(values) => {

                validation?.handleSubmit(values);
              }}
              action="/auth-signin-basic"
            >
              <Row>

                <Input
                  className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                  id="nameInput"
                  type="select"
                  name="proof_type"
                  value={validation.values.proof_type}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}

                >
                  <option key="0" value="" disabled>
                    Select proof Type
                  </option>
                  <option key="1" value="ITR Copy">
                    ITR Copy
                  </option>
                  <option key="2" value="Audited Balancesheet">
                    Audited balance sheet
                  </option>
                  <option key="3" value="Form 16">
                    Form 16
                  </option>
                  <option key="4" value="Latest net worth certificate">
                    Latest net worth certificate
                  </option>
                  <option key="5" value="Last Month's Salary Slip">
                    Last month's salary slip
                  </option>
                  <option
                    key="6"
                    value="Bank Account Statement for last 6 months"
                  >
                    Bank account statement for last 6 months
                  </option>
                  <option key="7" value="Demat Holding Statement">
                    Demat holding statement
                  </option>
                  <option key="8" value="Other investment Proof">
                    Other investment proof
                  </option>
                </Input>

                <Input
                  className="nominee-form-input text-color1 text-4 mt-3"
                  type="file"
                  id="formFile"
                  name="proof_file"
                  onBlur={validation.handleBlur}
                  onChange={(event) => {
                    validation.setFieldValue("proof_file", event.currentTarget.files[0]);
                  }}
                />
                <Input
                  type="text"
                  className="nominee-form-input text-color1 text-4 mt-3"
                  id="nameInput"
                  placeholder="Enter File Password"
                  name="file_Password"
                  maxLength={20}
                  value={validation.values.file_Password}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}

                />

                <div className="d-flex mb-3 p-0 mt-3">
                  <button
                    className="btn btn-primary me-3 additional-page-button-save"
                    type="submit"
                    disabled={disableButton == 0 ? false : true}
                  //id="password-addon"
                  //onClick={() => setPasswordShow(!passwordShow)}
                  >

                    {disableButton == 0 ? 'Save' : <img src={loaderImage} className="custom-loader" style={{ height: "20px", marginRight: "5px" }} />}
                  </button>
                  <button
                    className="additional-page-button-cancel me-2"
                    type="button"
                    //id="password-addon"
                    onClick={() => {
                      setActive(false);
                      setSegment([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Segments;
















