import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { MOD_EKYC_NEW } from '../../../helpers/url_helper';

const SelfieCapture = ({ onCapture }) => {
  const [isCameraReady, setIsCameraReady] = useState(false); // To track if the camera is ready
  const [imageSrc, setImageSrc] = useState(null); // Store the captured selfie image
  const videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element
  const client_code = localStorage.getItem("client_id");
  const [err,setError]=useState('')

  // Initialize the video stream and display it on the screen
  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' }, // User-facing camera (front camera)
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setIsCameraReady(true);
        }
      } catch (err) {
        console.error('Error accessing webcam: ', err);
        alert('Unable to access the camera.');
      }
    };

    startCamera();

    // Cleanup function to stop the camera when the component unmounts
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop all tracks
      }
    };
  }, []);


  const captureSelfie = async() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      const width = video.videoWidth;
      const height = video.videoHeight;

      // Set canvas size to match video size
      canvas.width = width;
      canvas.height = height;

      // Draw the current video frame onto the canvas
      ctx.drawImage(video, 0, 0, width, height);

      // Get the base64 image data from the canvas
      const dataUrl = canvas.toDataURL('image/png');
      setImageSrc(dataUrl);
      await uploadSelfie(dataUrl);
     
    }
  };

  const uploadSelfie = async (imageData) => {
    setError('')
  
    let newStr = imageData.replace('data:image/png;base64,', "");
    try {
      // Sending the captured image to the API using axios
      const response = await axios.post(
        MOD_EKYC_NEW+'/check-liveliness',
        {
        
          client_code:client_code.trim(),
          base64Image:newStr

        },
        
      );
  
      const result = response; // Automatically parsed JSON response
      if(result?.status ==true){
      
        if (onCapture) {
          onCapture(imageData); // Pass the captured image back to the parent
        }
      }
      else{
        setError(result.msg)
      }
  
   
  
    } catch (error) {
      console.error('Error during API call:', error);
      setError('An error occurred while uploading the selfie.');
    }
  };
  const takeAnotherSelfie = async () => {
    setError('')
    setImageSrc(null); // Reset the image preview
    if (onCapture) {
      onCapture(null); // Pass the captured image back to the parent
    }
    // Stop the current stream
    const currentStream = videoRef.current?.srcObject;
    if (currentStream) {
      const tracks = currentStream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop all tracks of the current stream
    }

    setIsCameraReady(false); // Reset the camera ready state

    // Re-initialize the camera
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setIsCameraReady(true);
        }
      } catch (err) {
        console.error('Error accessing webcam: ', err);
        alert('Unable to access the camera.');
      }
    };

    await startCamera(); // Start the camera again
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>

      {/* Video Element to show camera feed */}
      {!imageSrc && (
        <div>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              width: '300px', // Set the width of the video
              height: '300px', // Set the height of the video
              borderRadius: '50%', // Make the video circular
              objectFit: 'cover', // Ensure the video fits inside the circle
              display: 'block', // Remove extra space under the video
              margin: '0 auto', // Center the video horizontally
            }}
          />
        </div>
      )}

      {/* Button to capture the selfie */}
      {!imageSrc && (
        <button
          onClick={captureSelfie}
          disabled={!isCameraReady}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#5b71b9',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Capture Selfie
        </button>
      )}

      {/* Display the captured image */}
      {imageSrc && (
        <div style={{ marginTop: '20px' }}>
         
          <img
            src={imageSrc}
            alt="Captured Selfie"
            style={{
              width: '300px', // Set width for the image
              height: '300px', // Set height for the image
              borderRadius: '50%', // Make the image circular
              objectFit: 'cover', // Ensure the image fits nicely inside the circle
            }}
          />
        </div>
      )}
<p style={{color:'red',marginTop:'10px'}}>{err}</p>
      {/* Button to take another selfie */}
      {imageSrc && (
        <button
          onClick={takeAnotherSelfie}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#5b71b9',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Take Another Selfie
        </button>
      )}

      {/* Canvas to capture the image from video (hidden) */}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default SelfieCapture;
