import React, { useState, useEffect } from "react";
import { shoppingCart } from "../../common/data/ecommerce";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
//Import Breadcrumb
import { Link } from "react-router-dom";

import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap";
import WithdrawFunds from "../../Components/CustomComponents/Models/CompleatePayment/WithdrawFunds";
import { sendApi } from "../../helpers/fakebackend_helper";

import BankDetailsModelIndex from "../../Components/CustomComponents/Models/BankDetails/BankDetailsModelIndex";
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import {
  downloadMarginOverviewApi,
  latestUpdateOnApi,
  ledgerApi,
  marginApi,
  collateralApi,
} from "../../helpers/fakebackend_helper";
import { getCollateral } from "../../store/Collateral/action";
import hdfc from "../../assets/images/logo/hdfc.png";
import Encripted from "../Dashboard/Encripted";
import { paymentBackApp } from "../../helpers/url_helper";

const PaymentInfo = (props) => {
  const [activePay, setActivePay] = useState(1);
  const [passedSteps, setPassedSteps] = useState(true);
  const [selectedBank, setSelectedBank] = useState(1);
  const [addBank, setAddBank] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);

  const [ledger, setLedger] = useState({});
  const [margin, setMargin] = useState({});
  const [collateral, setCollateral] = useState({});
  const [ledgerBalances, setLedgerBalance] = useState(0);
  const [marginBalances, setMarginBalance] = useState(0);

  const dispatch = useDispatch();
  const { Cart } = useSelector((state) => ({
    Cart: state.Cart.Cart,
  }));
  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const { Checkout } = useSelector((state) => ({
    Checkout: state.PaymentDetails.PaymentDetails,
  }));
  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));

  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch]);

  useEffect(() => {
    ledgerApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
    
        const d = response.finResult.find((el) => {
          return el.LNarrat === "Total : ";
        });
        setLedger(d);
        if (d.Lbal < 0) {
          setLedgerBalance(Math.abs(d.Lbal));
        } else if (d.Lbal > 0) {
          setLedgerBalance(-Math.abs(d.Lbal));
        } else {
          setLedgerBalance(0);
        }
        //console.log(d);
      })
      .catch((err) => {
        ////console.log(err);
      });
    marginApi({
      apiKey: generatedKey,
      code: encryptKey,
      startDate: dayjs(new Date("1/4/2015")).format("YYYY-MM-DD"),
      endDate: dayjs(new Date()).format("YYYY-MM-DD"),
    })
      .then((response) => {
        // console.log(response);
        const d = response.finResult.find((el) => {
          return el.LNarrat === "Total : ";
        });
        if (d.Lbal < 0) {
          setMarginBalance(Math.abs(d.Lbal));
        } else if (d.Lbal > 0) {
          setMarginBalance(-Math.abs(d.Lbal));
        } else {
          setMarginBalance(0);
        }
        setMargin(d);
        //console.log(d);
      })
      .catch((err) => {
        ////console.log(err);
      });
  }, [generatedKey, encryptKey]);

  const openState = {
    CUpdate: true,
    otp1: false,
    done: false,
  };
  // //console.log(Cart);
  ////console.log(Checkout);
  // //console.log(passedSteps);
  const values = [];
  Cart.length > 0 &&
    Cart.forEach((element) => {
      // if(element.feature_or_plan === "Plan") {
      //   const value = element.feature_or_plan_id;
      //   values.push(value);
      // }  else {
      //   const value = element.feature_or_plan_id;
      //   values.push(value);
      // }
      values.push(element.feature_or_plan_id);
      // //console.log(values);
    });

  const newValue = values.toString();
  ////console.log(props.data.availableMargin[0].Final1);
  const val = {
    client_code: client_code,
    client_token: client_token,
    subscriber_ids:
      Checkout !== null &&
      !isEmpty(Checkout) &&
      Checkout.subscriber_ids.length > 0 &&
      Checkout.subscriber_ids.toString(),
    terms_and_conditions: 1,
    payoption: "ledger",
  };
  const val2 = {
    client_code: client_code,
    // client_token: client_token,
    subscriber_ids:
      Checkout !== null &&
      !isEmpty(Checkout) &&
      Checkout.subscriber_ids.length > 0 &&
      Checkout.subscriber_ids.toString(),
    terms_and_conditions: 1,
    payoption: "bank",
  };
  if (Checkout !== null && !isEmpty(Checkout)) {
    localStorage.setItem(
      "subscriber_ids",
      Checkout.subscriber_ids.length > 0 && Checkout.subscriber_ids.toString()
    );
  }

  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push(
        (el.CollateralQuantity * el.BuyAvgPrice * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });

  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  const A =
    !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
      ? ledgerBalances - marginBalances / 2
      : 0;
  //console.log(A);
  const B =
    !isEmpty(ledger) && !isEmpty(margin) && Collateral.length > 0
      ? CollateralValuessubTotal - marginBalances / 2
      : 0;

  let WithdrawalAmount = 0;

  if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances <= 0
  ) {
    WithdrawalAmount = 0;
  } else if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances > 0 &&
    A < 0
  ) {
    WithdrawalAmount = 0;
  } else if (
    !isEmpty(ledger) &&
    !isEmpty(margin) &&
    Collateral.length > 0 &&
    ledgerBalances > 0 &&
    A > 0 &&
    B > 0
  ) {
    WithdrawalAmount = A + 0;
  } else {
    WithdrawalAmount = A + B;
  }

  const amountFormatter3 = (data) => {
    let Total = 0;
    if (data > 0) {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      return `- ₹${data.toLocaleString("en-IN").toString().splice(1)}`;
    } else {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  ////console.log(val);
  const addBankHandler = (data) => {
    setAddBank(data);
  };
  const selectBankDetails = (data) => {
    setSelectedBank(data);
  };
  const selectPaymentMethod = () => {
    setPassedSteps(!passedSteps);
  };
  //console.log(WithdrawalAmount);
  // const amountFormatter = (data) => {
  //   if (+data > 0) {
  //     //  ////console.log(data);
  //     return `${data.toString().slice(1)}`;
  //   }
  //   //  ////console.log(data);
  //   return `${data.toString().slice(1)}`;
  // };

  const amountFormatter = (data) => {
    if (data > 0) {
      ////console.log(data);
      return `- ₹${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      ////console.log(data);
      return `₹${data.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  const amountFormatter2 = (data) => {
    if (data > 0) {
      ////console.log(data);
      return `₹${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      ////console.log(data);
      return ` -₹${data.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      return `₹${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  const addFundHandler = () => {
    window.open(
      `https://payment.bigul.co/?ClientCode=${Encripted([])}`,
      "_blank"
    );
  };

  const payBankHandler = () => {
  
    setActivePay(2);
    sendApi(val2)
      .then((response) => {
        console.log(response);
        if (response.status === true) {
        
          const payload ={
            ClientCode:client_code,
            amount:atob(response?.adam),
            SubscriptionPlan:'subscriptionPlanNew',
            redirectUrl:paymentBackApp,
      
          }
         
           window.location.href = 'https://payment.bigul.co/v1/?enc=' + Encripted([payload])
          // window.open(`${response.data.url}`, "_self");
        }
      })
      .catch((err) => {
        ////console.log(err);
      });
  };
  const flippedValue = availableMargin?.availableMargin[0]?.Final1 < 0 ? Math.abs(availableMargin?.availableMargin[0]?.Final1) : -Math.abs(availableMargin?.availableMargin[0]?.Final1);

  
  // useEffect(() => {
  //   if(Checkout.ledger_balance > Checkout.amount === true) {
  //     setActivePay(1);
  //   } else {
  //     setActivePay(2);
  //   }
  // }, [Checkout])
  //console.log(activePay)
  //console.log(Checkout.ledger_balance)
  //console.log(Checkout.amount)
  //console.log(Checkout.ledger_balance > Checkout.amount)
  return (
    <React.Fragment>
      <div className="px-3">
        <h5 className="mb-3">Payment Selection</h5>
        {/* <p className="text-muted mb-4">
          Please select and enter your billing information
        </p> */}
      </div>

      <Row className="g-4 px-3 mb-3">
        <Col lg={12} sm={12}>
          <div className="d-flex align-items-center">
            {passedSteps &&
            !isEmpty(Checkout) &&
            -availableMargin?.availableMargin[0]?.Final1 > Checkout?.amount ===
              true ? (
              <button
                type="button"
                className={`px-4 py-2 me-3 padding-buttom-remove3 ${
                  activePay === 1 ? "checkout-btn4" : "checkout-btn2"
                }`}
                onClick={() => {
                  setActivePay(1);
                }}
              >
                <i className="ri-wallet-3-line align-bottom me-2"></i>Ledger
                Balance
              </button>
            ) : (
              <button
                type="button"
                disabled
                className={`px-4 py-2 me-3 padding-buttom-remove3 ${
                  WithdrawalAmount > Checkout.amount === false
                    ? "checkout-btn41"
                    : "checkout-btn2"
                }`}
                // onClick={() => {
                //   setActivePay(1);
                // }}
              >
                <i className="ri-wallet-3-line align-bottom me-2"></i>Ledger
                Balance
              </button>
            )}
            <button
              className={`px-4 py-2 padding-buttom-remove3 ${
                activePay === 2 ? "checkout-btn5" : "checkout-btn3"
              }`}
              onClick={payBankHandler}
              type="button"
            >
              <i className="ri-bank-fill align-bottom me-2"></i>Pay via Bank
            </button>
          </div>
        </Col>
        {/* <Col lg={4} sm={6}>
          <div>
            <div className="form-check card-radio">
              <Input
                id="paymentMethod01"
                name="paymentMethod"
                type="radio"
                className="form-check-input"
                defaultChecked
                onChange={selectPaymentMethod}
              />
              <Label
                className="form-check-label"
                htmlFor="paymentMethod01"
                style={{ borderRadius: "5px" }}
              >
                <span className="fs-16 text-muted me-2">
                  <i className="ri-wallet-3-line align-bottom"></i>
                </span>
                <span className="fs-14 text-wrap">Ledger Balance</span>
              </Label>
            </div>
          </div>
        </Col>
        <Col lg={4} sm={6}>
          <div>
            <div className="form-check card-radio">
              <Input
                id="paymentMethod02"
                name="paymentMethod"
                type="radio"
                className="form-check-input"
                onChange={selectPaymentMethod}
              />
              <Label
                className="form-check-label"
                htmlFor="paymentMethod02"
                style={{ borderRadius: "5px" }}
              >
                <span className="fs-16 text-muted me-2">
                  <i className="ri-bank-fill align-bottom"></i>
                </span>
                <span className="fs-14 text-wrap">Pay via bank</span>
              </Label>
            </div>
          </div>
        </Col> */}
      </Row>
      {passedSteps === true ? (
        <div
          className="px-3"
          style={{
            borderTop: "1px solid #eee7e7",
            //borderBottom: "1px solid #eee7e7",
          }}
        >
          <div className="d-flex align-items-center my-4 display-block">
            <div className="d-flex checkout-1 justify-content-center align-items-center">
              <p className="text-22 text-color1 m-0 me-2">Available Balance:</p>
              {/* <h2
              className={`me-4 text-9 mb-0 ${
                props.data.availableMargin.length > 0 &&
                (+props.data.availableMargin[0].Final1 > 0
                  ? "text-color11"
                  : "text-color6")
              }`}
            > */}
              {/* {`₹ ${
              props.data.availableMargin.length > 0 ?
              amountFormatter(props.data.availableMargin[0].Final1) : amountFormatter(0)
            } `} */}
              {/* {amountFormatter(
                props.data.availableMargin.length > 0
                  ? props.data.availableMargin[0].Final1
                  : 0
              )} */}
              {/* </h2> */}

              <h2
                className={`me-4 text-9 mb-0 ${
                  flippedValue > 0
                    ? "text-color6"
                    : "text-color11"
                }`}
              >
                {/* {`₹ ${
              props.data.availableMargin.length > 0 ?
              amountFormatter(props.data.availableMargin[0].Final1) : amountFormatter(0)
            } `} */}
                {/* Old margin */}
                {/* {amountFormatter(
               availableMargin.availableMargin.length > 0
               ? availableMargin.availableMargin[0].Final1
               : 0
              )} */}
                {amountFormatter(
                  availableMargin.availableMargin.length > 0
                    ? availableMargin.availableMargin[0].Final1
                    : 0,
                  CollateralValuessubTotal
                )}
              </h2>
            </div>
            {/* <button
              type="button"
              className="addFund-feature-button-normal"
              onClick={addFundHandler}
            >
              Add Fund
            </button> */}
            {/* {passedSteps &&
            !isEmpty(Checkout) &&
            Checkout.ledger_balance > Checkout.amount ? (
              <WithdrawFunds openState={openState} cartData={val} />
            ) : !passedSteps ? (
              <WithdrawFunds openState={openState} cartData={val} />
            ) : (
              <button
                type="button"
                className="btn btn-success btn-label right ms-auto compleate-order-btn"
                disabled
              >
                <i className=" ri-checkbox-circle-line label-icon align-middle fs-16 ms-2"></i>
                Proceed to Pay
              </button>
            )} */}
            
            {passedSteps &&
            availableMargin.availableMargin.length > 0 &&
            activePay === 1 &&
            flippedValue >
              Checkout.amount ? (
              <div className="checkout-1 margin-start-auto ms-auto margin-top-33">
                <WithdrawFunds
                  openState={openState}
                  cartData={val}
                  data={props}
                />
              </div>
            ) : activePay === 1 && !passedSteps ? (
              <div className="checkout-1 margin-start-auto ms-auto margin-top-33">
                <WithdrawFunds
                  openState={openState}
                  cartData={val}
                  data={props}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : passedSteps === false && addBank === true ? (
        <div className="collapse show" id="paymentmethodCollapse">
          <div className="px-3">
            <div className="d-flex justify-content-between">
              <h4 className="text-16 text-color1 mt-3">Saved Bank</h4>
              <button
                type="button"
                className="btn btn-primary btn-sm text-5 add-bank-button"
                onClick={() => {
                  addBankHandler(false);
                }}
              >
                Add Bank
              </button>
            </div>
            <Row
              className={`${
                selectedBank === 1 ? "selected-bank-details" : "nominee-details"
              } mt-3 mx-0 py-3`}
              onClick={() => {
                selectBankDetails(1);
              }}
            >
              <Col lg={1} xs={3}>
                <div className="bank-details-image-div me-4">
                  <img src={hdfc} className="bank-details-image" />
                </div>
              </Col>
              <Col lg={3} xs={6}>
                {/* <img src={hdfc} className="bank-details-image" /> */}
                <h2 className="text-10 text-color7">HDFC Bank</h2>
                <div className="d-flex">
                  <h4 className="text-11 text-color9 me-2 bank-details-badge p-1">
                    Primary{" "}
                  </h4>
                  <h4 className="text-11 text-color9">
                    {/* <i className="ri-edit-line fs-5 text-color5" /> */}
                    {/* <EditChages /> */}
                  </h4>
                </div>
              </Col>
              <Col lg={4}>
                <h2 className="text-11 text-color9 ">Bank Account Number</h2>
                <h4 className="text-4 text-color1">*4582</h4>
              </Col>

              <Col lg={4}>
                <h2 className="text-11 text-color9 ">IFSC Code</h2>
                <h4 className="text-4 text-color1">HDFC0000522</h4>
              </Col>
            </Row>
            <Row
              className={`${
                selectedBank === 2 ? "selected-bank-details" : "nominee-details"
              } mt-3 mx-0 py-3`}
              onClick={() => {
                selectBankDetails(2);
              }}
            >
              <Col lg={1} xs={3}>
                <div className="bank-details-image-div me-4">
                  <img src={hdfc} className="bank-details-image" />
                </div>
              </Col>
              <Col lg={3} xs={6}>
                {/* <img src={hdfc} className="bank-details-image" /> */}
                <h2 className="text-10 text-color7">HDFC Bank</h2>
                <div className="d-flex">
                  <h4 className="text-11 text-color9 me-2 bank-details-badge p-1">
                    Primary{" "}
                  </h4>
                  <h4 className="text-11 text-color9">
                    {/* <i className="ri-edit-line fs-5 text-color5" /> */}
                    {/* <EditChages /> */}
                  </h4>
                </div>
              </Col>
              <Col lg={4}>
                <h2 className="text-11 text-color9 ">Bank Account Number</h2>
                <h4 className="text-4 text-color1">*4582</h4>
              </Col>

              <Col lg={4}>
                <h2 className="text-11 text-color9 ">IFSC Code</h2>
                <h4 className="text-4 text-color1">HDFC0000522</h4>
              </Col>
            </Row>
            <Row
              className={`${
                selectedBank === 3 ? "selected-bank-details" : "nominee-details"
              } mt-3 mx-0 py-3`}
              onClick={() => {
                selectBankDetails(3);
              }}
            >
              <Col lg={1} xs={3}>
                <div className="bank-details-image-div me-4">
                  <img src={hdfc} className="bank-details-image" />
                </div>
              </Col>
              <Col lg={3} xs={6}>
                {/* <img src={hdfc} className="bank-details-image" /> */}
                <h2 className="text-10 text-color7">HDFC Bank</h2>
                <div className="d-flex">
                  <h4 className="text-11 text-color9 me-2 bank-details-badge p-1">
                    Primary{" "}
                  </h4>
                  <h4 className="text-11 text-color9">
                    {/* <i className="ri-edit-line fs-5 text-color5" /> */}
                    {/* <EditChages /> */}
                  </h4>
                </div>
              </Col>
              <Col lg={4}>
                <h2 className="text-11 text-color9 ">Bank Account Number</h2>
                <h4 className="text-4 text-color1">*4582</h4>
              </Col>

              <Col lg={4}>
                <h2 className="text-11 text-color9 ">IFSC Code</h2>
                <h4 className="text-4 text-color1">HDFC0000522</h4>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="collapse show px-3" id="paymentmethodCollapse">
          <div className="d-flex align-items-center mb-3">
            <div className="me-auto">
              <h4 className="text-9 text-color5 m-0">Add New Bank</h4>
              <p className="text-color2 text-15 m-0">
                Add new bank details here.
              </p>
            </div>
            <div>
              <BankDetailsModelIndex />
            </div>
            <div>
              <button
                type="button"
                className="additional-page-button-cancel me-2"
                onClick={() => {
                  addBankHandler(true);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <Card className="p-4 border shadow-none mb-0 mt-4">
            <div>
              <form action="#">
                <Row className="mb-3">
                  <Col lg={3}>
                    <Label
                      htmlFor="nameInput"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      Bank Name
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <select
                      className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                      id="nameInput"
                    >
                      <option>Select Bank Name</option>
                      <option key="1" value="1">
                        Bank 1
                      </option>
                    </select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <Label
                      htmlFor="nameInput"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      Bank Account Number
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Input
                      type={passwordShow ? "text" : "password"}
                      className="nominee-form-input text-color1 text-4"
                      id="nameInput"
                      placeholder="**** **** 0000"
                    />
                    {!passwordShow && (
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-off-line"></i>
                      </button>
                    )}
                    {passwordShow && (
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-line"></i>
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <Label
                      htmlFor="nameInput"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      IFSC Code
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <select
                      className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                      id="nameInput"
                    >
                      <option>Select Bank IFSC Code</option>
                      <option key="1" value="1">
                        DHCD201235
                      </option>
                    </select>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <Label
                      htmlFor="nameInput"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      MICR Number
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Input
                      type={passwordShow ? "text" : "password"}
                      className="nominee-form-input text-color1 text-4"
                      id="nameInput"
                      placeholder="**** **** 0000"
                    />
                    {!passwordShow && (
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-off-line"></i>
                      </button>
                    )}
                    {passwordShow && (
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-line"></i>
                      </button>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <Label
                      htmlFor="nameInput"
                      className="py-2 m-0 text-16 text-color5"
                    >
                      Bank Preference
                    </Label>
                  </Col>
                  <Col lg={6}>
                    <Label check className="me-3">
                      <Input
                        type="radio"
                        className="me-3"
                        // id="nameInput"
                        // placeholder="**** **** 0000"
                        name="radio1"
                      />
                      Primary
                    </Label>
                    <Label check>
                      <Input
                        type="radio"
                        className="me-3"
                        // id="nameInput"
                        // placeholder="**** **** 0000"
                        name="radio1"
                      />
                      Secondary
                    </Label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}> </Col>
                  <Col lg={6}>
                    <p className="text-11 text-color8">
                      *Note: Setting bank preference as Primary requires E-Sign
                      Verification.{" "}
                    </p>
                  </Col>
                </Row>
              </form>
            </div>
          </Card>
        </div>
      )}

      {/* <div className="d-flex align-items-start gap-3 my-4 px-3">
        <button
          type="button"
          className="btn btn-success btn-label right ms-auto compleate-order-btn"
        >
          <i className=" ri-checkbox-circle-line label-icon align-middle fs-16 ms-2"></i>
          Complete Order
        </button>
        {passedSteps &&
        (props.data.availableMargin[0].Final1 < 0 ||
          props.data.availableMargin[0].Final1 > Checkout.amount) ? (
          <WithdrawFunds openState={openState} cartData={val} />
        ) : (
          <button
            type="button"
            className="btn btn-success btn-label right ms-auto compleate-order-btn"
            disabled
          >
            <i className=" ri-checkbox-circle-line label-icon align-middle fs-16 ms-2"></i>
            Complete Order
          </button>
        )}
      </div> */}
    </React.Fragment>
  );
};

export default PaymentInfo;
