import React, { useState, useEffect, useMemo, useCallback } from "react";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader1 from "../../Components/Common/Loader1";

import Loader from "../../Components/Common/Loader";


import TableContainer2 from "../../Components/CustomComponents/TableContainer2";
import {
  taxReportListApi,
  taxDetailsApi,
  taxReportApi,
} from "../../helpers/fakebackend_helper";


const TaxReport = (props) => {
  
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const [finalData2, satFinalData2] = useState([]);

  const endDate = new Date();
  endDate.setDate(endDate.getDate() - 30);
  


  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0:
        return <div className="text-color6">{data.value.toFixed(2)}</div>;
      case data.value < 0:
        return <div className="text-color11">{data.value.toFixed(2)}</div>;
      default:
        return <div>{data.value}</div>;
    }
  };

  // Customers Column
  const columns = useMemo(
    () => [
      {
        Header: "Tax Type",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Gain/Loss",
        accessor: "GainLoss",
        filterable: true,
        Cell: AmountFormatter,
      },
      {
        Header: "Tax Amount",
        accessor: "TaxAmount",
        filterable: true,
        Cell: AmountFormatter,
      },
    ],
    []
  );

  // Export Modal
  const [list, setList] = useState([]);
  const [listReport, setListReport] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [taxState, setTaxState] = useState({
    taxList: false,
  });

console.log(props,'list');
useEffect(()=>{
if(props?.activeTab == 7 && list.length > 0){
  setLoading(false);
  taxDetailsApi({ id:list[0].id, code: client_code, token: client_token })
      .then((response) => {
        //(response);
        if (response && response.status === true) {
          setListReport(response.data);
          //setTaxState({ taxList: true });
        }
        taxReportApi({
          apiKey: props.generatedKey,
          code: props.encryptKey,
          start_year: '2024',
          end_year: '2025',
        })
          .then((response) => {
            ////console.log(response);
            setFinalData(response.ltstsummerizedvalueResult);
            setIsResponse(true);
           
            setLoading(false);

          })
          .catch((err) => {
            setLoading(false);
            toast.error('something went wrong')
          });
        // setIsDownload(true);
      })
      .catch((err) => {
        setLoading(false);
        // setListReport([]);
      });
}
},[props])


  useEffect(() => {
    ////console.log('response');
    taxReportListApi({ code: client_code, token: client_token })
      .then((response) => {
        ////console.log(response);
        if (response && response.status === true) {
          setList(response.data);
          setTaxState({ taxList: true });
        }

        // setIsDownload(true);
      })
      .catch((err) => {
        ////console.log(err);
      });
  }, []);

  const firstData = [];
  const long = {}
  const short = {}
  const speculation = {}
  const total = {}

  const updateCartHandler = (e) => {
    setLoading(true);
    satFinalData2([]);

    const id = list.find((el) => el.id === +e.target.value);
    // //console.log(id);
    const split_string = id.FinancialYear.split("-");
    // //console.log(split_string);
    taxDetailsApi({ id: id.id, code: client_code, token: client_token })
      .then((response) => {
        //(response);
        if (response && response.status === true) {
          setListReport(response.data);
          //setTaxState({ taxList: true });
        }
        taxReportApi({
          apiKey: props.generatedKey,
          code: props.encryptKey,
          start_year: split_string[0],
          end_year: split_string[1],
        })
          .then((response) => {
            ////console.log(response);
            setFinalData(response.ltstsummerizedvalueResult);
            setIsResponse(true);
           
            setLoading(false);

          })
          .catch((err) => {
            setLoading(false);
            toast.error('something went wrong')
          });
        // setIsDownload(true);
      })
      .catch((err) => {
        setLoading(false);
        // setListReport([]);
      });
  };

  if (isResponse == true) {
    long.name = "LONG TERM (More than 365 Days)";
    long.GainLoss = finalData.length > 0 && +finalData[0].lterm;
    long.TaxAmount = finalData.length > 0 && +finalData[0].lterm > 0 ? ((+listReport.LongTerm * finalData[0].lterm) / 100) : 0;
    ////console.log(listReport);
    ////console.log(long);
    short.name = "SHORT TERM (More than 30 Days)";
    short.GainLoss = finalData.length > 0 && +finalData[0].sterm;
    short.TaxAmount = finalData.length > 0 && +finalData[0].sterm > 0 ? ((+listReport.ShortTerm * finalData[0].sterm) / 100) : 0;
    ////console.log(short);
    speculation.name = "SPECULATION (Intraday - No Delivery)";
    speculation.GainLoss = finalData.length > 0 && +finalData[0].specl;
    speculation.TaxAmount = finalData.length > 0 && +finalData[0].specl > 0 ? ((+listReport.Speculation * finalData[0].specl) / 100) : 0;
    ////console.log(speculation);
    total.name = "Total";
    total.GainLoss = finalData.length > 0 && +finalData[0].lterm + +finalData[0].sterm + +finalData[0].specl;
    total.TaxAmount = finalData.length > 0 && (long.TaxAmount + short.TaxAmount + speculation.TaxAmount);
    firstData.push(long);
    firstData.push(short);
    firstData.push(speculation);
    firstData.push(total);
    // //console.log(firstData);
    satFinalData2(firstData)
    ////console.log(finalData2);
    setIsResponse(false);
  }





 
  return (
    <React.Fragment>
      <div className="custom-border2">
        <div className="d-flex justify-content-between justify-content-center p-3">
          <div className="d-flex  align-items-center w-75 width-mobile-50">
            <h4 className="mb-0 me-4 text-10">Select</h4>
            <select
              name="paymentMode"
              //value={list}
              className="form-select w-25 me-3 width-mobile-50"
              aria-label="Default select example"
              style={{ borderRadius: "5px" }}
              onChange={(e) => {
                updateCartHandler(e);
              }}
            >
              {/* <option value="">Please Select Tax Year</option> */}
              {list.length > 0 &&
                list.map((el, index) => {
                  // //console.log(el);
                  return (
                    <option key={index} value={el.id}>
                      {el.FinancialYear}
                    </option>
                  );
                })}
            </select>
          </div>
         
        </div>
      </div>
      {loading === false && finalData2.length ? (
        <TableContainer2
          columns={columns}
          data={finalData2 || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          tableTrClasses='summary-table-tr px-4'
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : loading === true && finalData2.length === 0 ? (
        <Loader />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default TaxReport;
