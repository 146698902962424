// import React, { useEffect, useState } from 'react';
// import './Tooltip.css';
// import Loader from '../../Components/Common/Loader';
// import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { DIGIO_FAMILY_SUBMITION, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY } from '../../helpers/url_helper';
// import Encrypted from '../Dashboard/Encripted';
// import Share1 from "../../Components/CustomComponents/Models/share/Share1";
// import Share from './../../../src/assets/images/user-icon.svg';
// import refresh from './../../../src/assets/images/refresh.svg';

// const FamilyTable = () => {
//     const location = useLocation();
//     const client_code = localStorage.getItem('client_id');
//     const client_token = localStorage.getItem('client_token');
//     const client_type = localStorage.getItem('client_type');
//     const [accounts, setAccounts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [openmodal, setOpenmodal] = useState(false);
//     const { profileDetails } = useSelector((state) => ({
//         profileDetails: state.ProfileDetails.userProfile,
//     }));
//     const [data1, setData] = useState([]);
//     const [selectedItems, setSelectedItems] = useState([]);
//     const [selectedMember, setSelectedMember] = useState(null);
//     const HUF = accounts?.filter(rel => rel?.relation === '6');
//     const fourDigit = HUF[0]?.pangir.trim();
//     const fourthCharacter = fourDigit === undefined ? 'P' : fourDigit[3];
//     const selfCount = accounts?.filter(account => account?.relation == 1 || account?.rejection?.relation == 1);
//     const selfCountReject = accounts?.filter(account => account?.rejection?.relation == 1);


//     const spouceCount = accounts?.filter(account => account.relation == 2);
//     const DependentParentCount = accounts?.filter(account => account.relation == 3);
//     const relationOptions = (selfCount.length > 0 && selfCount[0].validate_status == 1) || (selfCountReject?.length > 0) ? [

//         { label: 'Spouse', value: 2 },
//         { label: 'Dependent Parent', value: 3 },
//         { label: 'Dependent Child', value: 4 },
//         { label: 'HUF', value: 5 },
//         { label: 'AOP', value: 6 }
//     ] :
//         [
//             { label: 'Self', value: 1 },

//         ]

//     const relationOptions1 = [
//         { label: 'Self', value: 1 },
//     ]


//     const handleRelationChange = (id, newRelation, member, check) => {


//         const selectedRelation = check == 1 ? relationOptions1 : relationOptions.find(option => option.value === newRelation);
//         if (!selectedRelation) {
//             toast.error('Invalid relation selected');
//             return;
//         }
//         if (newRelation === 1 && (selfCount.length >= 1 && selfCount[0].validate_status == 1)) { // For "Self", ensuring only one "Self" is selected
//             toast.error('Self already selected, please select another relation');
//             return;
//         }
//         if (newRelation === 1) {
//             if (selfCount.length >= 1) {
//                 const previousSelf = selfCount[0];
//                 if (previousSelf.oowncode !== id) {
//                     setAccounts(prevContacts =>
//                         prevContacts.map(contact =>
//                             contact.oowncode === previousSelf.oowncode ? { ...contact, relation: null } : contact
//                         )
//                     );
//                 }
//             }
//         }
//         if (newRelation === 6 && fourthCharacter === "P") {
//             toast.error("For Relation AOP, Trading and demat account should be non-individual.");
//             setAccounts(prevContacts =>
//                 prevContacts.map(contact =>
//                     contact.oowncode === id ? { ...contact, relation: null } : contact
//                 )
//             );
//             return;
//         }
//         if (newRelation === 2 && spouceCount.length >= 1) {
//             toast.error('Spouse already selected, please select another relation');
//             return;
//         }
//         if (newRelation === 3) {
//             const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
//             const ageDifference = (new Date(selfCount[0]?.ddateofbirth) - newMemberDob) / (1000 * 3600 * 24 * 365); // Difference in years
//             if (ageDifference < 15) {
//                 toast.error(`Please update correction Relation.`);
//                 return;
//             }
//             if (DependentParentCount.length >= 1) {
//                 toast.error(`Dependent parent already selected please select another relation.`);
//             }
//         }
//         if (newRelation === 2 && (member?.cgender == selfCount[0]?.cgender)) {
//             toast.error('Spouse relation is not  allowed.');
//             return;
//         }
//         if (newRelation === 4) {
//             const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
//             const ageDifference = (newMemberDob - new Date(selfCount[0]?.ddateofbirth)) / (1000 * 3600 * 24 * 365);
//             if (ageDifference < 15) {
//                 toast.error('For relation "Dependent child", the age difference with "Self" must be at least 15 years.');
//                 return;
//             }
//         }
//         setAccounts(prevContacts =>
//             prevContacts.map(contact =>
//                 contact.oowncode === id ? { ...contact, relation: newRelation } : contact
//             )
//         );
//     };

//     const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);
//     const EMAIL = profileDetails[0]?.EMAIL.trim().slice(8);

//     const handleRemove = (record) => {
//         setOpenmodal(true)
//         setSelectedMember(record)
//         setAccounts(prevData =>
//             prevData.map(item => {
//                 if (item.oowncode === record?.oowncode) {
//                     return {
//                         ...item,
//                         primary: item.primary ? undefined : 'Done'
//                     };
//                 }
//                 return item;
//             })
//         );
//     };

//     const handleValidateAadhar = (item) => {
//         if (item?.relation === null) {
//             toast.error('Please select a relation');
//             return;
//         } else {
//             const payload = {
//                 f_client_code: item?.oowncode.trim(),
//                 client_code: client_code.trim(),
//                 client_token: client_token,
//                 client_type: client_type,
//                 relation: item.relation,
//                 mobile: item?.mobile.trim(),
//                 email: item?.email,
//                 name: item?.fibsacct,
//                 dob: item?.ddateofbirth
//             };

//             const headers = {
//                 'Content-Type': 'application/json',
//             };

//             axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
//                 .then(res => {
//                     if (res?.status === true) {
//                         setData(item);
//                        setSelectedItems([...selectedItems, item?.oowncode.trim()]);
//                         window.open(res?.link, '_blank');
//                     } else {
//                         toast(res?.message);
//                     }
//                 })
//                 .catch(error => {
//                     toast(error?.message || 'Something went wrong');
//                 });
//         }
//     };

//     const fetchFamilyInfo = async () => {
//         setLoading(true);
//         const payload = {
//             client_code: client_code,
//             mobile: profileDetails[0]?.MOBILE,
//         };

//         try {
//             const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });
//             if (response.status) {
//                 const updatedAccounts = response?.data.map(member => ({
//                     ...member,
//                     relation: member.relation,
//                 }));
//                 setAccounts(updatedAccounts);
//                 setLoading(false);
//             } else {
//                 toast('Something went wrong');
//                 setLoading(false);
//                 setAccounts([]);
//             }
//         } catch (err) {
//             toast('Something went wrong');
//             setAccounts([]);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         setData([]);
//         setLoading(true);
//         if (profileDetails[0]?.MOBILE?.length > 5) {
//             fetchFamilyInfo();
//         }
//     }, [profileDetails]);

//     const handlesubmit = (record) => {
//         const payload = {
//             client_code: client_code,
//             client_type: client_type,
//             family_declaration: [{
//                 client_code: client_code?.trim() || "",
//                 f_client_code: record.oowncode?.trim() || "",
//                 name: record?.fibsacct,
//                 dob: record.ddateofbirth?.trim() || "",
//                 digio_id: record.digio_id?.trim() || null,
//                 digio_response: record.digio_response?.trim() || null,
//                 validate_status: 0,
//                 relation: null,
//                 email: record.email,
//                 mobile: record.mobile,
//                 status: 3
//             }
//             ]
//         }
//         setLoading(true)
//         axios.post(DIGIO_FAMILY_SUBMITION, payload)
//             .then(res => {
//                 setLoading(false)
//                 if (res.status) {
//                     setOpenmodal(false)
//                     setSelectedMember(null)
//                     fetchFamilyInfo()
//                 } else {
//                     toast.error('something went wrong')
//                 }
//             })
//             .catch(error => {
//                 setLoading(false)
//                 toast(error?.message || 'something went wrong')
//             });
//     }

//     const handlecancle = () => {
//         setOpenmodal(false)
//         setSelectedMember(null)
//     }

//     const handleRelationChange1 = (newRelation) => {
//         setSelectedItems([...selectedItems, newRelation?.trim()]);


//     };

//     if (loading) {
//         return <Loader />;
//     }
//     return (
//         <div className="card-conrainer">
//             {openmodal && (
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: 0,
//                         left: 0,
//                         right: 0,
//                         bottom: 0,
//                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         zIndex: 1000,
//                     }}

//                 >
//                     <div
//                         style={{
//                             backgroundColor: 'white',
//                             padding: '20px',
//                             borderRadius: '5px',
//                             maxWidth: '400px',
//                             width: '100%',
//                             textAlign: 'center',
//                         }}

//                     >
//                         <div
//                             style={{
//                                 marginTop: '10px',
//                             }}
//                         >
//                             <p>Are you sure you want to remove <strong>{selectedMember?.fibsacct}</strong> from your family details</p>
//                             <button
//                                 style={{
//                                     padding: '10px 20px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     border: 'none',
//                                     backgroundColor: 'gray',
//                                     color: 'white',
//                                     borderRadius: '5px',
//                                     marginTop: '20px',
//                                 }}
//                                 onClick={() => { handlecancle() }}
//                             >
//                                 cancel
//                             </button>

//                             <button
//                                 style={{
//                                     padding: '10px 20px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     border: 'none',
//                                     backgroundColor: '#5367FC',
//                                     color: 'white',
//                                     borderRadius: '5px',
//                                     marginTop: '20px',
//                                     marginLeft: '20px'
//                                 }}
//                                 onClick={() => { handlesubmit(selectedMember) }}
//                             >
//                                 Submit
//                             </button>

//                         </div>
//                     </div>
//                 </div>
//             )}
//             <div className="card-row">
//                 <p style={{ color: '#231F20' }} className="breadcrumb-item active main-header-secondary-page mb-4">
//                     The Mobile number: <span style={{ fontWeight: '600' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: '600' }}>XXXXXXX{EMAIL}</span> you provided are linked to multiple trading accounts. Please update the relationships as per SEBI guidelines.
//                 </p>
//                 {accounts?.map((member) => (
//                     <div className="card-col" key={member.oowncode}>
//                         <div className="relation-cards">
//                             <div className="name-part">
//                                 <div className="left">
//                                     <div className="icon">
//                                         <img src={Share} style={{ height: '20px', width: '20px' }} />
//                                     </div>
//                                     <div>
//                                         <h5>{member?.fibsacct}</h5>
//                                         <p>{member?.oowncode}</p>
//                                     </div>
//                                 </div>
//                                 <div className={member.status === 0 ? 'pending' : member.status === 1 ? 'pending' : member.status === 2 ? 'verified' : member.status === 3 ? 'reject' : (member.status === null && member?.rejection?.reason) ? 'reject' : ''}>

//                                     <p>{(member.status === 0) ? 'Pending for validation' : (member.status === 1) ? 'Pending for verification' : member.status === 2 ? 'Verified' : member.status === 3 ? 'Removed' : (member.status === null && member?.rejection?.reason) ? 'Rejected' : ''}</p>
//                                 </div>
//                             </div>
//                             <div className="relation-part">
//                                 <h4>Your relation</h4>
//                                 <div className="btn-part">
//                                     {
//                                         ((member.relation == 1 && member.validate_status == 1) || (member.rejection?.relation == 1))
//                                             ?
//                                             relationOptions1.map((option) => (
//                                                 <label key={option.value} className="rdo-btn"
//                                                     style={{
//                                                         opacity: 0.5
//                                                     }}>
//                                                     <input
//                                                         type="radio"
//                                                         name={`radio-${member.oowncode}`}
//                                                         value={option.value}
//                                                         checked={member.relation === option.value}
//                                                         onChange={() => { handleRelationChange(member.oowncode, option.value, member, 1) }}

//                                                     />
//                                                     <div className="checkmark">{option.label}</div>
//                                                 </label>
//                                             ))
//                                             :
//                                             relationOptions.map((option) => (
//                                                 <label key={option.value} className="rdo-btn"
//                                                     style={{
//                                                         opacity: member?.validate_status === 1 || member.status === 3 || member.status === 1 || (member.status == 0 && member.relation == 1) ? 0.5 : 1
//                                                     }}>
//                                                     <input
//                                                         type="radio"
//                                                         name={`radio-${member.oowncode}`}
//                                                         value={option.value}
//                                                         checked={member.relation === option.value}
//                                                         onChange={() => { handleRelationChange(member.oowncode, option.value, member) }}
//                                                         disabled={member?.validate_status === 1 || member.status == 3 || member.status == 1 || (member.status == 0 && member.relation == 1)}
//                                                     />
//                                                     <div className="checkmark">{option.label}</div>
//                                                 </label>
//                                             ))}
//                                 </div>
//                             </div>
//                             {
//                                 (member.validate_status == 0 && member?.rejection == null)
//                                 &&
//                                 <div
//                                     style={{
//                                         padding: '5px',
//                                         background: 'rgba(241, 91, 77, 0.1)', // #F15B4D with 10% transparency
//                                         width: '50%',
//                                         textAlign: 'center',
//                                         cursor: member.validate_status == 1 || member?.status == 3 ? 'not-allowed' : 'pointer', // Change cursor to 'not-allowed' when disabled
//                                         borderRadius: '8px',
//                                         border: '1px solid #F15B4D', // Border color #F15B4D
//                                     }}
//                                     onClick={() => {
//                                         // Only allow the click if the conditions are not met
//                                         if (member?.validate_status != 1 && member?.status != 3) {
//                                             handleRemove(member);
//                                         }
//                                     }}
//                                 >
//                                     <p style={{ color: '#F15B4D',fontSize:'12px' }}> Remove from family X</p>
//                                 </div>
//                             }
//                             <div className="pan-dob-part">
//                                 <div className="pan-box">
//                                     <h5>PAN :</h5>
//                                     <p style={{ marginLeft: '5px' }}>{member?.pangir}</p>
//                                 </div>
//                                 <div className="pan-box">
//                                     <h5>DOB: </h5>
//                                     <p style={{ marginLeft: '5px' }}> {member?.ddateofbirth ? (new Date(member.ddateofbirth).getDate()).toString().padStart(2, '0') + '/' + (new Date(member.ddateofbirth).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(member.ddateofbirth).getFullYear() : ''}</p>
//                                 </div>
//                             </div>
//                             {
//                                 ((member.status == null && member?.rejection == null) && member?.primary !== 'Done') &&
//                                 <p class="para">Share link with {member?.fibsacct} to validate her/him identity.</p>

//                             }
//                             {
//                                 (member.status == 0 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#E5C100' }}>{member?.fibsacct} identity is still pending for validation. Re-share link to validate.</p>

//                             }
//                             {
//                                 (member.status == 1 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#E5C100' }}>Change of relation {member?.fibsacct} identity is pending for final verification</p>

//                             }
//                             {
//                                 (member.status == 3 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#F15B4D' }}>Your request to remove {member?.fibsacct}from family is pending for approval</p>

//                             }
//                             {
//                                 ((member.status == null && member?.rejection?.reason) && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#F15B4D' }}>Your change in relation with {member?.fibsacct} is rejected due to {member?.rejection?.reason}</p>

//                             }
//                             {
//                                 (member?.primary == 'Done') &&
//                                 <p class="para">By clicking on submit,{member?.fibsacct}will be remove from your family.</p>

//                             }
//                             {member?.validate_status === 0 && member?.status !== 3 && !selectedItems?.includes(member?.oowncode.trim()) && (
//                                 <div className="button-part">
//                                     <button className="btn" onClick={() => handleValidateAadhar(member)}>Validate</button>
//                                     or
//                                     <Share1 newData={member} account={member?.relation} onRelationChange={handleRelationChange1} />
//                                 </div>
//                             )}
//                             {(selectedItems?.includes(member?.oowncode.trim()) && member.validate_status == 0) &&
//                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                     <a
//                                         href="#"
//                                         className="default-link"
//                                         onClick={(e) => {
//                                             e.preventDefault();
//                                             // fetchFamilyInfo();
//                                             window.location.reload();
//                                         }}
//                                     >
//                                         Refresh to check status
//                                         <img
//                                             src={refresh} // Replace with your actual SVG file path
//                                             alt="Refresh Icon"
//                                             style={{ marginLeft: '4px', width: '13px', height: '13px' }} // Style it as needed
//                                         />
//                                     </a>
//                                 </div>

//                             }

//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <p style={{ textAlign: 'center' }}>{accounts.length == 0 && 'Record not found'}</p>
//             <div style={{ height: '20px' }}></div>
//         </div>
//     );
// };

// export default FamilyTable;


import React, { useEffect, useState } from 'react';
import './Tooltip.css';
import Loader from '../../Components/Common/Loader';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DIGIO_FAMILY_SUBMITION, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY } from '../../helpers/url_helper';
import Encrypted from '../Dashboard/Encripted';
import Share1 from "../../Components/CustomComponents/Models/share/Share1";
import Share from './../../../src/assets/images/user-icon.svg';
import refresh from './../../../src/assets/images/refresh.svg';
import closeIcon from './../../../src/assets/images/close.svg';

const FamilyTable = () => {
    const location = useLocation();
    const client_code = localStorage.getItem('client_id');
    const client_token = localStorage.getItem('client_token');
    const client_type = localStorage.getItem('client_type');
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openmodal, setOpenmodal] = useState(false);
    const { profileDetails } = useSelector((state) => ({
        profileDetails: state.ProfileDetails.userProfile,
    }));
    const [data1, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const HUF = accounts?.filter(rel => rel?.relation === '6');
    const fourDigit = HUF[0]?.pangir.trim();
    const fourthCharacter = fourDigit === undefined ? 'P' : fourDigit[3];
    const selfCount = accounts?.filter(account => account?.relation == 1 || account?.rejection?.relation == 1);
    const selfCountReject = accounts?.filter(account => account?.rejection?.relation == 1);
    const spouceCount = accounts?.filter(account => account.relation == 2);
    const DependentParentCount = accounts?.filter(account => account.relation == 3);
    const relationOptions = (selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ? [

        { label: 'Spouse', value: 2 },
        { label: 'Dependent Parent', value: 3 },
        { label: 'Dependent Child', value: 4 },
        { label: 'HUF', value: 5 },
        { label: 'AOP', value: 6 }
    ] :
        [
            { label: 'Self', value: 1 },

        ]

    const relationOptions1 = [
        { label: 'Self', value: 1 },
    ]




    const handleRelationChange = (id, newRelation, member, check) => {


        const selectedRelation = check == 1 ? relationOptions1 : relationOptions.find(option => option.value === newRelation);
        if (!selectedRelation) {
            toast.error('Invalid relation selected');
            return;
        }
        if (newRelation === 1 && (selfCount?.length >= 1 && selfCount[0]?.validate_status == 1)) { // For "Self", ensuring only one "Self" is selected
            toast.error('Self already selected, please select another relation');
            return;
        }
        if (newRelation === 1) {
            if (selfCount?.length >= 1) {
                const previousSelf = selfCount[0];
                if (previousSelf.oowncode !== id) {
                    setAccounts(prevContacts =>
                        prevContacts.map(contact =>
                            contact.oowncode === previousSelf.oowncode ? { ...contact, relation: null } : contact
                        )
                    );
                }
            }
        }
        if (newRelation === 6 && fourthCharacter === "P") {
            toast.error("For Relation AOP, Trading and demat account should be non-individual.");
            setAccounts(prevContacts =>
                prevContacts.map(contact =>
                    contact.oowncode === id ? { ...contact, relation: null } : contact
                )
            );
            return;
        }
        if (newRelation === 2 && spouceCount.length >= 1) {
            toast.error('Spouse already selected, please select another relation');
            return;
        }
        if (newRelation === 3) {
            const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
            const ageDifference = (new Date(selfCount[0]?.ddateofbirth) - newMemberDob) / (1000 * 3600 * 24 * 365); // Difference in years
            if (ageDifference < 15) {
                toast.error(`Please update correction Relation.`);
                return;
            }
            if (DependentParentCount.length >= 1) {
                toast.error(`Dependent parent already selected please select another relation.`);
            }
        }
        if (newRelation === 2 && (member?.cgender == selfCount[0]?.cgender)) {
            toast.error('Spouse relation is not  allowed.');
            return;
        }
        if (newRelation === 4) {
            const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
            const ageDifference = (newMemberDob - new Date(selfCount[0]?.ddateofbirth)) / (1000 * 3600 * 24 * 365);
            if (ageDifference < 15) {
                toast.error('For relation "Dependent child", the age difference with "Self" must be at least 15 years.');
                return;
            }
        }
        setAccounts(prevContacts =>
            prevContacts.map(contact =>
                contact.oowncode === id ? { ...contact, relation: newRelation } : contact
            )
        );
    };

    const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);
    const EMAIL = profileDetails[0]?.EMAIL.trim().slice(8);

    const handleRemove = (record) => {
        setOpenmodal(true)
        setSelectedMember(record)
        setAccounts(prevData =>
            prevData.map(item => {
                if (item.oowncode === record?.oowncode) {
                    return {
                        ...item,
                        primary: item.primary ? undefined : 'Done'
                    };
                }
                return item;
            })
        );
    };

    const handleValidateAadhar = (item, val) => {
        if (item?.relation === null && val == 0) {
            toast.error('Please select a relation');
            return;
        } else {
            const payload = {
                f_client_code: item?.oowncode.trim(),
                // client_code: client_code.trim(),
                client_code: (selfCount[0]?.validate_status == 1 || selfCount[0]?.rejection?.relation == 1) ? selfCount[0]?.oowncode : item?.oowncode,
                client_token: client_token,
                client_type: client_type,
                relation: val == 1 ? 1 : item.relation,
                mobile: item?.mobile.trim(),
                email: item?.email,
                name: item?.fibsacct,
                dob: item?.ddateofbirth
            };

            const headers = {
                'Content-Type': 'application/json',
            };

            axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
                .then(res => {
                    if (res?.status === true) {
                        setData(item);
                        setSelectedItems([...selectedItems, item?.oowncode.trim()]);
                        window.open(res?.link, '_blank');
                    } else {
                        toast(res?.message);
                    }
                })
                .catch(error => {
                    toast(error?.message || 'Something went wrong');
                });
        }
    };

    const fetchFamilyInfo = async () => {
        setLoading(true);
        const payload = {
            client_code: client_code,
            mobile: profileDetails[0]?.MOBILE,
        };

        try {
            const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });
            if (response.status) {
                const updatedAccounts = response?.data.map(member => ({
                    ...member,
                    relation: member.relation,
                }));
                setAccounts(updatedAccounts);
                setLoading(false);
            } else {
                toast('Something went wrong');
                setLoading(false);
                setAccounts([]);
            }
        } catch (err) {
            toast('Something went wrong');
            setAccounts([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        setData([]);
        setLoading(true);
        if (profileDetails[0]?.MOBILE) {
            fetchFamilyInfo();
        }
    }, [profileDetails[0]?.MOBILE]);

    const handlesubmit = (record) => {
        const payload = {
            client_code:selfCount[0]?.oowncode,
            client_type: client_type,
            family_declaration: [{
                client_code: selfCount[0]?.oowncode || "",
                f_client_code: record.oowncode?.trim() || "",
                name: record?.fibsacct,
                dob: record.ddateofbirth?.trim() || "",
                digio_id: record.digio_id?.trim() || null,
                digio_response: record.digio_response?.trim() || null,
                validate_status: 0,
                relation: null,
                email: record.email,
                mobile: record.mobile,
                status: 3
            }
            ]
        }
        setLoading(true)
        axios.post(DIGIO_FAMILY_SUBMITION, payload)
            .then(res => {
                setLoading(false)
                if (res.status) {
                    setOpenmodal(false)
                    setSelectedMember(null)
                    fetchFamilyInfo()
                } else {
                    toast.error('something went wrong')
                }
            })
            .catch(error => {
                setLoading(false)
                toast(error?.message || 'something went wrong')
            });
    }

    const handlecancle = () => {
        setOpenmodal(false)
        setSelectedMember(null)
    }

    const handleRelationChange1 = (newRelation) => {
        setSelectedItems([...selectedItems, newRelation?.trim()]);


    };

    if (loading) {
        return <Loader />;
    }
    return (
        <div className="card-conrainer">
            {openmodal && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}

                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '5px',
                            maxWidth: '400px',
                            width: '100%',
                            textAlign: 'center',
                        }}

                    >
                        <div
                            style={{
                                marginTop: '10px',
                            }}
                        >
                            <p>Are you sure you want to remove <strong>{selectedMember?.fibsacct}</strong> from your family details</p>
                            <button
                                style={{
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    border: 'none',
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                }}
                                onClick={() => { handlecancle() }}
                            >
                                cancel
                            </button>

                            <button
                                style={{
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    border: 'none',
                                    backgroundColor: '#5367FC',
                                    color: 'white',
                                    borderRadius: '5px',
                                    marginTop: '20px',
                                    marginLeft: '20px'
                                }}
                                onClick={() => { handlesubmit(selectedMember) }}
                            >
                                Submit
                            </button>

                        </div>
                    </div>
                </div>
            )}
            <div className="card-row">
                <p style={{ color: '#231F20' }} className="breadcrumb-item active main-header-secondary-page mb-4">
                    The Mobile number: <span style={{ fontWeight: '600' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: '600' }}>XXXXXXX{EMAIL}</span> you provided are linked to multiple trading accounts. {selfCount[0]?.validate_status == 1 ? 'Please update the relationships as per SEBI guidelines.' : 'Please select your primary (Self) account from the below mentioned profiles as per SEBI guidelines.'}
                </p>
                {accounts?.map((member) => (
                    <div className="card-col" key={member.oowncode}>
                        <div className="relation-cards">
                            <div className="name-part">
                                <div className="left">
                                    <div className="icon">
                                        <img src={Share} style={{ height: '20px', width: '20px' }} />
                                    </div>
                                    <div>
                                        <h5>{member?.fibsacct}</h5>
                                        <p>{member?.oowncode}</p>
                                    </div>
                                </div>
                                <div className={member.status === 0 ? 'pending' : member.status === 1 ? 'pending' : member.status === 2 ? 'verified' : member.status === 3 ? 'reject' : (member.status === null && member?.rejection?.reason) ? 'reject' : ''}>

                                    {/* <p style={{ fontWeight: '600' }}>{(member.status === 0) ? 'Pending validation' : (member.status === 1) ? 'Pending for verification' : member.status === 2 ? 'Verified' : member.status === 3 ? 'Removed' : (member.status === null && member?.rejection?.reason) ? 'Rejected' : ''}</p> */}
                                    <p style={{ fontWeight: '600' }}>{member?.status_msg}</p>
                                </div>
                            </div>
                            <div className="pan-dob-part pt-0">
                                <div className="pan-box">
                                    <h5>PAN no:</h5>
                                    <p style={{ marginLeft: '5px' }}>{member?.pangir}</p>
                                </div>
                                <div className="pan-box">
                                    <h5>Date of birth: </h5>
                                    <p style={{ marginLeft: '5px' }}> {member?.ddateofbirth ? (new Date(member.ddateofbirth).getDate()).toString().padStart(2, '0') + '/' + (new Date(member.ddateofbirth).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(member.ddateofbirth).getFullYear() : ''}</p>
                                </div>
                            </div>
                            <div className="relation-part">
                                {
                                    // (selfCount?.length > 0 && (selfCount[0]?.validate_status === 1 || member?.rejection?.relation == 1)) &&
                                    // member.validate_status == 0 && member.status !== 3 &&
                                    (member.validate_status == 0 && (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0)) && member.relation !== 1 &&
                                    member.status !== 3 &&

                                    <h4>Select your relation</h4>
                                }
                                <div className="btn-part">
                                    {
                                        (selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ?
                                            ((member.relation == 1 && member.validate_status == 1) || (member.rejection?.relation == 1))
                                                ?
                                                relationOptions1?.map((option) => (
                                                    <label key={option.value} className="rdo-btn"
                                                        style={{
                                                            opacity: 0.5
                                                        }}>
                                                        <input
                                                            type="radio"
                                                            name={`radio-${member.oowncode}`}
                                                            value={option.value}
                                                            checked={member.relation === option.value}
                                                            onChange={() => { handleRelationChange(member.oowncode, option.value, member, 1) }}

                                                        />
                                                        <div className="checkmark">{option.label}</div>
                                                    </label>
                                                ))
                                                :
                                                relationOptions?.map((option) => (
                                                    <label key={option.value} className="rdo-btn"
                                                        style={{
                                                            opacity: member?.validate_status === 1 || member.status === 3 || member.status === 1 || (member.status == 0 && member.relation == 1) ? 0.5 : 1
                                                        }}>
                                                        <input
                                                            type="radio"
                                                            name={`radio-${member.oowncode}`}
                                                            value={option.value}
                                                            checked={member.relation === option.value}
                                                            onChange={() => { handleRelationChange(member.oowncode, option.value, member) }}
                                                            disabled={member?.validate_status === 1 || member.status == 3 || member.status == 1 || (member.status == 0 && member.relation == 1)}
                                                        />
                                                        <div className="checkmark">{option.label}</div>
                                                    </label>
                                                )) :
                                            null
                                    }
                                </div>
                            </div>
                            {member?.rejection?.relation !== 1 && member.validate_status == 0 &&
                                (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0) && member.relation !== 1 &&
                                member.status !== 3 &&
                                // (member.relation !== 1 && selfCount?.length > 0 && (selfCount[0]?.validate_status === 1 || member?.rejection?.relation == 1)) &&
                                // member.validate_status == 0 && member.status !== 3 &&

                                <div
                                    style={{
                                        padding: '5px',
                                        background: 'rgba(241, 91, 77, 0.1)', // #F15B4D with 10% transparency
                                        width: '50%',
                                        textAlign: 'center',
                                        cursor: member.validate_status == 1 || member?.status == 3 || (member?.rejection?.relation == 1) ? 'not-allowed' : 'pointer', // Change cursor to 'not-allowed' when disabled
                                        borderRadius: '8px',
                                        border: '1px solid #F15B4D',
                                        display: 'flex ',// Border color #F15B4D
                                        alignItems: 'center'
                                    }}
                                    onClick={() => {
                                        // Only allow the click if the conditions are not met
                                        if (member?.validate_status != 1 && member?.status != 3) {
                                            handleRemove(member);
                                        }
                                    }}
                                >
                                    <p style={{ color: '#F15B4D', fontSize: '12px', paddingLeft: '6px' }}> Remove from family</p>
                                    <img
                                        src={closeIcon}// Replace with the actual path to your SVG icon
                                        alt="Remove"
                                        style={{
                                            width: '13px', // Adjust size as needed
                                            height: '13px', // Adjust size as needed
                                            marginLeft: '6px', // Space between the icon and text
                                            verticalAlign: 'middle',
                                            color: '#F15B4D',
                                            // Align the icon vertically with the text
                                        }}
                                    />
                                </div>
                            }

                            {/* {
                                ((member.status == null && member?.rejection == null) && member?.primary !== 'Done') &&
                                <p class="para">Share link with {member?.fibsacct} to validate her/him identity.</p>

                            } */}
                            {
                                (member.status == 0 && member?.primary !== 'Done') &&
                                <p class="para" style={{ color: '#E5C100' }}>{member?.fibsacct} identity is still pending for validation. Re-share link to validate.</p>

                            }
                            {
                                (member.status == 1 && member?.primary !== 'Done') &&
                                <p class="para" style={{ color: '#E5C100' }}>Change of relation {member?.fibsacct} identity is pending for final verification</p>

                            }
                            {
                                (member.status == 3 && member?.primary !== 'Done') &&
                                <p class="para" style={{ color: '#F15B4D' }}>Your request to remove {member?.fibsacct}from family is pending for approval</p>

                            }
                            {
                                ((member.status == null && member?.rejection?.reason) && member?.primary !== 'Done') &&
                                <p class="para" style={{ color: '#F15B4D' }}>Your change in relation with {member?.fibsacct} is rejected due to {member?.rejection?.reason}</p>

                            }
                            {
                                (member?.primary == 'Done') &&
                                <p class="para">By clicking on submit,{member?.fibsacct}will be remove from your family.</p>

                            }
                            {(selfCount?.length > 0 && selfCount[0]?.validate_status === 1) || (selfCountReject?.length > 0) ?
                                member?.validate_status === 0 && member?.status !== 3 && !selectedItems?.includes(member?.oowncode.trim()) && (
                                    <div className="button-part mt-3">
                                        <button className="btn" onClick={() => handleValidateAadhar(member, 0)}>{member?.rejection !== null && 'Re-'}Validate</button>
                                        or
                                        <Share1 newData={member} account={member?.relation} onRelationChange={handleRelationChange1} />
                                    </div>
                                )
                                :
                                (!selectedItems?.includes(member?.oowncode.trim()))
                                &&
                                <div className="button-part mt-3">
                                    <button className="btn" onClick={() => handleValidateAadhar(member, 1)}>Declare Self</button>
                                </div>
                            }
                            {(selectedItems?.includes(member?.oowncode.trim()) && member.validate_status == 0) &&
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                    <a
                                        href="#"
                                        style={{border: '1px solid #5367fc', fontSize: '14px',fontWeight: 400,color:'#5367fc'}}
                                        className="default-link btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            // fetchFamilyInfo();
                                            window.location.reload();
                                        }}
                                    >
                                        Refresh to check status
                                        <img
                                            src={refresh} // Replace with your actual SVG file path
                                            alt="Refresh Icon"
                                            style={{ marginLeft: '7px', width: '13px', height: '13px' }} // Style it as needed
                                        />
                                    </a>
                                </div>

                            }

                        </div>
                    </div>
                ))}
            </div>
            <p style={{ textAlign: 'center' }}>{accounts.length == 0 && 'Record not found'}</p>
            <p style={{ marginTop: '20px', textAlign: 'center', fontWeight: '400', color: '#231F20' }}>{accounts.length == 0 ? null : 'Please complete the process within 15 days; otherwise, all pending accounts will be deactivated.'}</p>
            <div style={{ height: '20px' }}></div>
        </div>
    );
};

export default FamilyTable;





