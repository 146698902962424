import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";

import TradeBookCash from "./TradeBookCash";
import TradeBookDerivative from "./TradeBookDerivative";
import Ledger from "./Ledger";
import PAndLCash from "./PAndLCash";
import PAndLDerivative from "./PAndLDerivative";
import Holdings from "./Holdings";
import TaxReport from "./TaxReport";
import ContractNote from "./ContractNote";
import PMS from "./PMS";

const SubscriptionPlanHistoryTable = () => {
  const { ID } = useParams();

  // console.log(ID)
  const [activeTab, setActiveTab] = useState(ID === undefined ? "3" : ID);
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));

  return (
    <React.Fragment>
      <Card className="p-0">
        <div className="card-body py-0 px-0">
          <div>
            <Nav
              className="nav-tabs nav-tabs-custom justify-content-between statement-menu"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "3" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("3", "Ledger");
                  }}
                  href="#"
                >
                  Ledger
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "6" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("6", "Holdings");
                  }}
                  href="#"
                >
                  Holdings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "4" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("4", "P&L Cash");
                  }}
                  href="#"
                >
                  P&L (Cash)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "5" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("5", "P&L Derivative");
                  }}
                  href="#"
                >
                  P&L (Derivative)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "7" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("7", "Tax Report");
                  }}
                  href="#"
                >
                  Tax Report
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("1", "TradeBook Cash");
                  }}
                  href="#"
                >
                  Transaction (Cash)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("2", "TradeBook Derivative");
                  }}
                  href="#"
                >
                  Transaction (Derivative)
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "8" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("8", "Contract Note");
                  }}
                  href="#"
                >
                  Contract Note
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "9" },
                    "padding-nav-item-text"
                  )}
                  onClick={() => {
                    toggleTab("9", "PMS");
                  }}
                  href="#"
                >
                  PMS
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <TradeBookCash
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
              <TabPane tabId="2">
                <TradeBookDerivative
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
              <TabPane tabId="3">
                <Ledger generatedKey={generatedKey} encryptKey={encryptKey} />
              </TabPane>
              <TabPane tabId="4">
                <PAndLCash
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
              <TabPane tabId="5">
                <PAndLDerivative
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
              <TabPane tabId="6">
                <Holdings generatedKey={generatedKey} encryptKey={encryptKey} />
              </TabPane>
              <TabPane tabId="7">
                <TaxReport
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                  activeTab={activeTab}
                />
              </TabPane>
              <TabPane tabId="8">
                <ContractNote
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
              <TabPane tabId="9">
                <PMS
                  generatedKey={generatedKey}
                  encryptKey={encryptKey}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default SubscriptionPlanHistoryTable;
