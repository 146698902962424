import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
import {
  SymphonyWithdrawalBalance
} from "../../helpers/fakebackend_helper";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";
import Loader1 from "../../Components/Common/Loader1";
//action
import { getLedger } from "../../store/statements/action";

import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";
import loaderImage from "../../assets/images/Loader.png";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCollateral } from "../../store/Collateral/action";

const Ledger = (props) => {
  const dispatch = useDispatch();
  const [total, setTotal] = useState("");
  const [counter, setCounter] = useState(0);
  const getFinancialYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    if (currentMonth < 3) {
      return new Date(currentYear - 1, 1, 1);
    } else {
      return new Date();
    }
  }
  const [fYear, setFYear] = useState(getFinancialYear());
  const { generatedKey, encryptKey } = props;

  // from date from 1st april of the current year
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const defaultStartDate = new Date(currentYear, 3, 1);
  const [startDate1, setStartDate1] = useState(defaultStartDate);
  const [cashBalance, setcashBalance] = useState(0);

  //to date from 31st march  of next year
  const nextYear = currentYear + 1;
  const defaultEndDate = new Date(nextYear, 2, 31);
  const [endDate1, setEndDate1] = useState(new Date());
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const symphony_token = localStorage.getItem("symphony_token");
  const client_type = localStorage.getItem("client_type");
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  // const startDate = new Date();
  // const endDate = new Date();
  // startDate.setDate(startDate.getDate() - 7);

  // const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const { ledger } = useSelector((state) => ({
    ledger: state.Statements.ledger,
  }));
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  //console.log(availableMargin)
  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));



  useEffect(() => {
    if(client_type == "Bigul"){
    setLoading(true)
    SymphonyWithdrawalBalance({
      client_token: client_token,
      token: symphony_token,
    })
      .then((response) => {
        console.log("SymphonyWithdrawalBalance99", response);
        console.log("SymphonyWithdrawalBalance99", response.balance_arr.withdrawal);
        if (!response.balance_arr.withdrawal) {
          setcashBalance(0);
          setLoading(false)

        } else {
          setcashBalance(parseFloat(response.balance_arr.CashMarginAvailable));
          setLoading(false)

        }
      })
      .catch((err) => {
        setWithdrawalBalance(0);
        // console.log(err);
      });
    }
  }, [generatedKey, encryptKey]);


  useEffect(() => {
    dispatch(
      getMarginOverview({ apiKey: props.generatedKey, code: props.encryptKey })
    );
    dispatch(
      getBankDetails({ apiKey: props.generatedKey, code: props.encryptKey })
    );
    dispatch(
      getCollateral({ apiKey: props.generatedKey, code: props.encryptKey })
    );
  }, [dispatch, props.generatedKey, props.encryptKey]);

  //console.log(total);
  function removeValue(value, index, arr) {
    // //console.log(value)
    // //console.log(index)
    // //console.log(arr)
    // If the value at the current array index matches the specified value (2)
    if (
      //value.LNarrat === "Opening Balance       " ||
      value.LNarrat === "Total : "
    ) {
      setTotal(arr[index]);
      // Removes the value from the original array
      arr.splice(index, 1);
      return true;
    }
    return false;
  }

  const charactersLimit = (data, limit) => {
    const withoutTags = data.replace(/(<([^>]+)>)/gi, "");
    if (withoutTags === undefined) {
      return withoutTags;
    }
    if (data !== null || data !== undefined) {
      if (withoutTags.length > limit) {
        return withoutTags.substring(0, limit).concat(" ...");
      }
    }
    return withoutTags;
  };
  // change the year in from and to after changing fyear
  useEffect(() => {
    ////console.log(fYear);
    // new Date("01-Apr-" + fYear);
    // const currentDate = new Date();
    ////console.log(new Date("01-Apr-" + fYear.getFullYear()));
    // setStartDate1(new Date(fYear.getFullYear(), 3, 1));
    setStartDate1(new Date(new Date().getFullYear(), new Date().getMonth(), 1));

    // setEndDate1(new Date(fYear.getFullYear() + 1, 2, 31));
    setEndDate1(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  }, [fYear]);

  // //console.log(endDate1);
  // Pass the removeValue function into the filter function to return the specified value
  const x = ledger.filter(removeValue);
  ////console.log(x)
  useEffect(() => {
    const f2Year1 = `${fYear.getFullYear()}`;
    const f2Year2 = `${fYear.getFullYear() + 1}`;
    dispatch(
      getLedger({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        // fYear : fYear.getFullYear()
        fYear: `${f2Year1.slice(-2)}-${f2Year2.slice(-2)}`,
        // startDate: dayjs(new Date("01-Apr-" + fYear.getFullYear())).format(
        //   "YYYY-MM-DD"
        // ),
        //  dayjs().startOf('month').format('YYYY-MM-DD'),
        // endDate: dayjs(new Date("01-Apr-" + fYear.getFullYear())).format(
        //   "YYYY-MM-DD"
        // ),
        // dayjs().format('YYYY-MM-DD'),
      })
    );
  }, [startDate1, endDate1]);

  const DateFilter = () => {
    const f2Year1 = `${fYear.getFullYear()}`;
    const f2Year2 = `${fYear.getFullYear() + 1}`;
    setCounter(2);
    dispatch(
      getLedger({
        apiKey: props.generatedKey,
        code: props.encryptKey,
        startDate: dayjs(startDate1).format("YYYY-MM-DD"),
        endDate: dayjs(endDate1).format("YYYY-MM-DD"),
        fYear: `${f2Year1.slice(-2)}-${f2Year2.slice(-2)}`,
      })
    );
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const AmountFormatter = (data) => {
    switch (true) {
      case data.value > 0:
        return <div className="text-color6 ms-5">{data.value.toFixed(2)}</div>;
      case data.value < 0:
        return <div className="text-color11 ms-5">{data.value.toFixed(2)}</div>;
      default:
        return <div className="ms-5">{data.value.toFixed(2)}</div>;
    }
  };

  function getCurrentFinancialYearMax(strDocDate) {
    var startYear = "";
    var endYear = "";
    var docDate = new Date(strDocDate);
    if (docDate.getMonth() + 1 <= 3) {
      startYear = docDate.getFullYear() - 1;
      endYear = docDate.getFullYear();
    } else {
      startYear = docDate.getFullYear();
      endYear = docDate.getFullYear() + 1;
    }
    return new Date("31-Mar-" + endYear);
  }

  const amountFormatter = (data) => {
    if (data > 0) {
      ////console.log(data);
      return `- ${data.toLocaleString("en-IN").toString()}`;
    } else if (data < 0) {
      ////console.log(data);
      return `${data.toLocaleString("en-IN").toString().slice(1)}`;
    } else {
      return `${data.toLocaleString("en-IN").toString()}`;
    }
    //  ////console.log(data);
  };

  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push(
        (el.CollateralQuantity * el.BuyAvgPrice * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });

  // //console.log(CollateralNew)
  // //console.log(CollateralValues)
  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  //console.log(CollateralValuessubTotal);

  //const TotalValue = availableMargin.availableMargin.length > 0 && +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  ////console.log(TotalValue);
  const a = 8;
  const b = 4;

  // let TotalValue = 0;
  // if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 > 0
  // ) {
  //   TotalValue =
  //     -Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 < 0
  // ) {
  //   TotalValue =
  //     Math.abs(+availableMargin.availableMargin[0].Final1) +
  //     CollateralValuessubTotal;
  // } else if (
  //   availableMargin.availableMargin.length > 0 &&
  //   +availableMargin.availableMargin[0].Final1 === 0
  // ) {
  //   TotalValue =
  //     +availableMargin.availableMargin[0].Final1 + CollateralValuessubTotal;
  // }

  function getCurrentFinancialYearMin(strDocDate) {
    var startYear = "";
    var endYear = "";
    var docDate = new Date(strDocDate);
    if (docDate.getMonth() + 1 <= 3) {
      startYear = docDate.getFullYear() - 1;
      endYear = docDate.getFullYear();
    } else {
      startYear = docDate.getFullYear();
      endYear = docDate.getFullYear() + 1;
    }
    ////console.log(new Date("01-Apr-" + startYear));
    return new Date("01-Apr-" + startYear);
  }

  const AmountFormatter2 = (data) => {
    // switch (true) {
    //   case data.value > 0:
    //     return <div className="text-color6">{data.value}</div>;
    //   case data.value < 0:
    //     return <div className="text-color11">{data.value}</div>;
    //   default:
    //     return <div>{data.value}</div>;
    // }
    return <div className="text-color11">{data.value}</div>;
  };

  const myArray = [];
  for (let i = 0; i < ledger.length; i += 1) {
    const childArray = ledger[i];
    const obj = {
      // Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      LNarrat: childArray.LNarrat.replace("#", ""),
      Damount: childArray.Damount,
      Camount: childArray.Camount,
      Lbal: childArray.Lbal,
    };
    myArray.push(obj);
  }

  const userHeader = [
    "Date",
    "Description",
    "Dr. Amount",
    "Cr. Amount",
    "Balance",
  ];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });

  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${!isEmpty(profileDetails) ? profileDetails[0].FIBSACCT.trim() : "USER"
    }_${dayjs(new Date()).format("YYYY-MM-DD")}_Ledger`
  );
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const downloadHandler = () => {
    setIsDownload(true);
  };

  const reversedTransactions = ledger?.reverse();
  console.log(reversedTransactions, 'check');


  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      {
        Header: "Date",
        accessor: "Ldate",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY");
        },
      },
      {
        //Header: "Description",
        Header: <h4 className="d-flex m-0 fs-13">Description</h4>,
        accessor: "LNarrat",
        filterable: true,
        Cell: (cell) => {
          return (
            <span
              className="d-flex"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={cell.value}
            >
              {charactersLimit(cell.value, 30)}
            </span>
          );
        },
      },
      {
        //Header: "Dr. Amount (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            Dr. Amount (₹)
          </h4>
        ),
        accessor: "Damount",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        // Cell: AmountFormatter2,
      },
      {
        //Header: "Cr. Amount (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">
            Cr. Amount (₹)
          </h4>
        ),
        accessor: "Camount",
        filterable: true,
        Cell: (cell) => {
          return (
            <span className="d-flex justify-content-end">
              {cell.value.toFixed(2)}
            </span>
          );
        },
        //Cell: AmountFormatter,
      },
      {
        //Header: "Balance (₹)",
        Header: (
          <h4 className="d-flex justify-content-end m-0 fs-13">Balance (₹)</h4>
        ),
        accessor: "Lbal",
        filterable: true,
        Cell: (cell) => {
          switch (true) {
            case cell.value > 0:
              return (
                <div className="text-color11 d-flex justify-content-end">
                  {-Math.abs(cell.value.toFixed(2))}
                </div>
              );
            case cell.value < 0:
              return (
                <div className="text-color6 d-flex justify-content-end">
                  {Math.abs(cell.value.toFixed(2))}
                </div>
              );
            default:
              return (
                <div className="d-flex justify-content-end">
                  {cell.value.toFixed(2)}
                </div>
              );
          }
        },
        //Cell: AmountFormatter,
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };


   function convertValue(value) {
    // Check if the value is negative
    if (value < 0) {
      // If negative, convert it to positive
      return Math.abs(value);
    } else {
      // If positive, convert it to negative
      return -value;
    }
  }
  let convertedValue = convertValue(availableMargin?.availableMargin[0]?.Final1);
  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);
  //console.log(total)
  return (
    <React.Fragment>
    
      {/* <div>
        <div className="d-flex justify-content-between p-3">
          <div className="input-group input-group-width-25">
            <Flatpickr
              placeholder="Select date"
              id="exampleInputdate"
              className="form-control summary-table-date"
              options={{
                mode: "range",
                dateFormat: "d M, Y",
                maxDate: new Date(),
              }}
              value={selectDate.date}
              onChange={(date) => {
                setSelectDate({ date: date });
              }}
            />
            <Label htmlFor="exampleInputdate" className="form-label">
              <span
                className="input-group-text summary-table-date px-2 py-0"
                id="basic-addon1"
              >
                <i className="ri-calendar-2-line fs-4 text-color1"></i>
              </span>
            </Label>
          </div>
          <div></div>

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-success text-4 margin-overview-button2"
              style={{ borderRadius: "5px" }}
              onClick={downloadHandler}
            >
              <i className="ri-download-2-line me-2 align-bottom"></i>
              Download Report
            </button>
          </div>
        </div>
      </div> */}
      <div>
        <div
          className="d-flex justify-content-between align-items-end  p-3 mobile-display-block text-center-statement"
          style={{ borderBottom: "1px solid #8080802b" }}
        >
          <div className="d-flex align-items-end mobile-display-block">
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement">
                {" "}
                Select Financial Year{" "}
              </h5>
              <div className="d-flex">
                <DatePicker
                  id="exampleInputdate"
                  showIcon
                  selected={fYear}
                  maxDate={getFinancialYear()}
                  onChange={(date) => setFYear(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  className="month-picker"
                />
                {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
              </div>
            </div>
            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> From </h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate"
                  className="form-control summary-table-date date-picker-statements"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    // minDate: getCurrentFinancialYearMin(fYear),
                    // minDate: "2013-04-01",
                    minDate: new Date(fYear.getFullYear(), 3, 1),
                    maxDate: new Date(fYear.getFullYear() + 1, 3, 0),
                    // enable: [
                    //   function (date) {
                    //     var startYear = "";
                    //     var endYear = "";
                    //     var docDate = new Date(fYear);
                    //     if (docDate.getMonth() + 1 <= 3) {
                    //       startYear = docDate.getFullYear();
                    //       endYear = docDate.getFullYear() + 1;
                    //     } else {
                    //       startYear = docDate.getFullYear();
                    //       endYear = docDate.getFullYear() + 1;
                    //     }
                    //     return (
                    //       date >= new Date("01-Apr-" + startYear) &&
                    //       date <= new Date("31-Mar-" + endYear)
                    //     );
                    //   },
                    // ],
                    // enable: [
                    //   function (date) {
                    //     return date >= startDate1;
                    //   },
                    // ],
                  }}
                  value={[startDate1]}
                  onChange={(date) => {
                    console.log(date);
                    setStartDate1(date[0]);
                  }}
                />
                {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
              </div>
            </div>

            <div className="input-group-width-50 me-4 mb-2">
              <h5 className="text-10 text-center-statement"> To</h5>
              <div className="d-flex">
                <Flatpickr
                  placeholder="Select date"
                  id="exampleInputdate"
                  className="form-control summary-table-date date-picker-statements"
                  options={{
                    //mode: "range",
                    dateFormat: "d M, Y",
                    // minDate: getCurrentFinancialYearMin(fYear),
                    // maxDate: getCurrentFinancialYearMax(fYear),
                    minDate: startDate1,
                    // minDate : new Date(fYear.getFullYear(),3,1),
                    // maxDate: new Date(),
                    enable: [
                      function (date) {
                        return date >= startDate1;
                      },
                    ],
                    // enable: [
                    //   function (date) {
                    //     var startYear = "";
                    //     var endYear = "";
                    //     var docDate = new Date(fYear);
                    //     if (docDate.getMonth() + 1 <= 3) {
                    //       startYear = docDate.getFullYear();
                    //       endYear = docDate.getFullYear() + 1;
                    //     } else {
                    //       startYear = docDate.getFullYear();
                    //       endYear = docDate.getFullYear() + 1;
                    //     }
                    //     // //console.log(date >= new Date("01-Apr-" + startYear))
                    //     // //console.log((date <= new Date("01-Apr-" + startYear) && date >= new Date("31-Mar-" + endYear)))
                    //     return (
                    //       date >= new Date("01-Apr-" + startYear) &&
                    //       date >= startDate1 &&
                    //       date <= new Date("31-Mar-" + endYear) &&
                    //       date >= startDate1
                    //     );

                    //     //return (date.getMonth() % 2 === 0 && date.getDate() < 15);
                    //   },
                    // ],
                    //   disable: [
                    //     {
                    //         from: new Date(startDate1),
                    //         to: new Date(startDate1)
                    //     }
                    // ]
                  }}
                  value={[endDate1]}
                  onChange={(date) => {
                    setEndDate1(date[0]);
                  }}
                />
                {/* <Label htmlFor="exampleInputdate" className="form-label">
                  <span
                    className="input-group-text summary-table-date px-2 py-0"
                    id="basic-addon1"
                  >
                    <i className="ri-calendar-2-line fs-4 text-color1"></i>
                  </span>
                </Label> */}
              </div>
            </div>
            <button
              type="button"
              className="text-4 mb-2 btn btn-primary margin-overview-button1"
              style={{ borderRadius: "5px", height: "50%" }}
              onClick={DateFilter}
            >
              Search
            </button>
          </div>
          <div className="text-center mobile-view-cashbal">
            <h5 className="text-10  text-color7 text-end mob-txt-amt">
              <span>Cash Balance : </span>
              <span
                className={`text-10  ${cashBalance || convertedValue > 0
                  ? "text-color"
                  : "text-color11" 
                  }`}
              >
                {" "}
                {client_type == 'Bonanza' ?
              availableMargin?.availableMargin.length == 0 ?
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={loaderImage} className="custom-loader" style={{ height: "20px", }} />
              </div> :
              '₹' + convertedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              :
                loading ?
                <div style={{display:'flex',justifyContent:'center'}}>
                  <img src={loaderImage} className="custom-loader" style={{ height: "20px",}} />
                   </div>
                  : '₹' + cashBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              </span>
            </h5>
            <div className="d-flex download-button-mobile">
              <button
                type="button"
                className="btn btn-success text-4 margin-overview-button2 new_btn"
                style={{ borderRadius: "5px", height: "50%" }}
                onClick={downloadHandler}
                disabled={ledger.length === 0}
              >
                <i className="ri-download-2-line me-2 align-bottom"></i>
                Download Report
              </button>
            </div>
          </div>
        </div>
      </div>
      {counter === 0 && ledger.length ? (
        <TableContainer2
          columns={columns}
          data={ledger || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          tableTrClasses="summary-table-tr px-4"
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : counter !== 0 ? (
        <Loader error={"empty"} />
      ) : (
        <Loader error={"empty"} />
      )}
    </React.Fragment>
  );
};

export default Ledger;
