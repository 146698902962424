import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  FormFeedback,
} from "reactstrap";
import { isEmpty } from "lodash";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
// import BankDetailsModelIndex from "../../../Components/CustomComponents/Models/BankDetails/BankDetailsModelIndex";
// import EditChages from "../../../Components/CustomComponents/Models/BankDetails/EditChages";
// import hdfc from "../../assets/images/logo/hdfc.png";
import { useSelector, useDispatch } from "react-redux";
import {
  AddBankApiCall,
  getBankMasterInfo,
  getBankMasterInfo2,
  pennyDrop,
  checkModificationStatus,
  DDPIShameESign,
} from "../../../helpers/fakebackend_helper";
import BankESignMod from "../../../Components/CustomComponents/Models/BankESignMod/BankESignMod";
import { toast } from "react-toastify";

const ShowBankDetails = (props) => {
  const { dd1, addBank2, fullBankPrimary, Balance } = props;
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  const incomeammount = profileDetails[0]?.NINCOME

  return (
    <>
      <Row>
        <Col className="profile-main-head">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <h4 className="text-9 text-color5 m-0">Bank Details</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary btn-sm text-5 nominee-page-button-save"
                onClick={() => {
                  if (incomeammount <= 0) {
                    toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
                    return
                  }
                  else {
                    if (addBank2) {
                      toast('You have already requested for bank updation.')
                    }
                    else {
                      props.addBank(true);
                    }
                  }


                }}
              >
                Add New Bank
              </button>
            </div>
          </div>
        </Col>
      </Row>

      {dd1.length > 0 && dd1.map((el, index) => {
        // console.log("i",index)
        const m = el.ACNUMBER.includes('M')

        return (
          <>
            <div key={index}>
              {/* <h4 className="text-16 text-color1 mt-3">Primary Account</h4> */}
              {!m && <Row className="nominee-details mt-3 mx-0 py-3">
                {/* <Col class="col-12 col-lg-3">
                  <h2 className="text-101 text-color7 mb-2">{el.BANK_NAME}</h2>
                  <div className="d-flex">
                    {el.Default_Bank == 1 ? <h4 className="text-11 text-color9 me-2  bank-details-badge p-1">
                      Primary{" "}
                    </h4> : <h4 className="text-11 text-color9 me-2 bank-details-badge2 p-1">
                      Other{" "}
                    </h4>}
                   
                  </div>
                </Col>
                <Col lg={3}>
                  <h2 className="text-11 text-color9 mb-2">Bank Account Number</h2>
                  <h4 className="text-4 text-color1">{el.ACNUMBER}</h4>
                </Col>

                <Col lg={3}>
                  <h2 className="text-11 text-color9 mb-2">IFSC Code</h2>
                  <h4 className="text-4 text-color1">{el.CIFSCCODE}</h4>
                </Col> */}

                <div class="col-12 col-lg-3">
                  <div class="d-flex d-md-block align-items-center mb-2">
                    <h2 class="text-101 text-color7 mb-0 mb-md-2 " style={{flex: "0 0 50%"}}>{el.BANK_NAME}</h2>
                    <div class="d-flex">
                    {el.Default_Bank == 1 ? <h4 className="text-11 text-color9 me-2  bank-details-badge p-1">
                      Primary{" "}
                    </h4> : <h4 className="text-11 text-color9 me-2 bank-details-badge2 p-1">
                      Other{" "}
                    </h4>}
                      {/* <h4 class="text-11 text-color9 me-2 bank-details-badge p-1">Primary </h4><h4 class="text-11 text-color9"></h4> */}
                      </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="d-flex d-md-block align-items-center mb-2">
                    <h2 class="text-11 text-color9 mb-0 mb-md-2 " style={{flex: "0 0 50%"}}>Bank Account Number</h2>
                    <h4 className="text-4 text-color1">{el?.ACNUMBER}</h4>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="d-flex d-md-block align-items-center mb-2">
                    <h2 class="text-11 text-color9 mb-0 mb-md-2 " style={{flex: "0 0 50%"}}>IFSC Code</h2>
                    
                    <h4 className="text-4 text-color1">{el?.CIFSCCODE}</h4>
                  </div>
                </div>

                {el.Default_Bank == 0 && (<Col lg={3}>

                  <h2 className="text-11 text-color9 ">Change Account Status</h2>
                  <div className="form-check form-switch">
                    <BankESignMod data={el} mainData={dd1} fullBankPrimary={fullBankPrimary} state={props.state} onStateChange={props.onStateChange} apicheck={props.apicheck} Balance={Balance} />
                  </div>
                </Col>)}
              </Row>}
            </div>
          </>
        );
      })}
    </>
  );
};

export default ShowBankDetails;
