// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { isEmpty } from "lodash";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   Card,
//   Modal,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Form,
//   Input,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
//   Button,
//   ModalHeader,
// } from "reactstrap";
// import DPScheme from "./DPScheme";
// import AdditionalDetailsModelIndex from "../../Components/CustomComponents/Models/additionalDetails/AdditionalDetailsModelIndex";
// import DPSchemeModelIndex from "../../Components/CustomComponents/Models/DPScheme/DPSchemeModelIndex";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   SegmentActivationApiCall,
//   checkModificationStatus,
//   dpShameList,
//   DDPIESign,
//   DDPIShameESign,
// } from "../../helpers/fakebackend_helper";
// import { color } from "echarts";


// const AdditionalDetails = (props) => {
//   const history = useNavigate();
//   const [eSign, setESign] = useState(false);
//   const [eSignStart, setESignStart] = useState(false);
//   const [eSignComplected, setESignComplected] = useState(false);
//   const [modStatusDDPI, setModStatusDDPI] = useState(0);
//   const [modStatusDPShame, setModStatusDPShame] = useState(0);
//   const [checked, setChecked] = useState(false);
//   const [scheme, setScheme] = useState(false);
//   const [selectedScheme, setSelectedScheme] = useState("");
//   const client_code = localStorage.getItem("client_id");
//   const [isCheckAll, setIsCheckAll] = useState(false);
//   const [isCheck, setIsCheck] = useState([]);
//   const [shameList, setShameList] = useState([]);
//   const [modal_signUpModals, setmodal_signUpModals] = useState(false);
//   const [dpSchemeName, setdpSchemeName] = useState();

//   //console.log(shameList)
//   const [eSign2, setESign2] = useState(false);
//   const [eSignStart2, setESignStart2] = useState(false);
//   const [eSignComplected2, setESignComplected2] = useState(false);

//   const { ClientID } = useSelector((state) => ({
//     ClientID: state.ClientID.ClientID
//   }));
//   const symphony_token = localStorage.getItem("symphony_token");

//   const [dPscheme, setDPScheme] = useState(false);

//   const { profileDetails } = useSelector((state) => ({
//     profileDetails: state.ProfileDetails,
//   }));
//   //console.log(profileDetails)
//   const openState = {
//     CChange1: false,
//     CChange2: true,
//   };


//   //console.log(profileDetails);
//   useEffect(() => {
//     if (props.activeTab === "2") {
//       dpShameList()
//         .then((response) => {
//           //console.log(response);
//           if (response.success === true) {
//             setShameList(response.data);
//           }
//           //props.sendData(modalMobile)
//         })
//         .catch((err) => {
//           ////console.log(err);
//         });
//     }
//   }, [props.activeTab]);

//   useEffect(() => {
//     if (props.activeTab === "2") {
//       checkModificationStatus({
//         client_code: client_code,
//         pan_no:
//           !isEmpty(profileDetails.userProfile[0]) &&
//           profileDetails.userProfile[0].PANGIR,
//         field_name: "dp_scheme",
//         esign_flag: "YES",
//       })
//         .then((response) => {
//           // console.log(response.data.dp_schemes);
//           // if (response.success === true) {
//           //   setModStatusDPShame(true);
//           // } else {
//           //   setModStatusDPShame(false);
//           // }
//           setdpSchemeName(response.data.dp_schemes);
//           if (
//             response.success === true &&
//             response.data.esign_status === false
//           ) {
//             setModStatusDPShame(1);
//           } else if (response.success === false) {
//             setModStatusDPShame(2);
//           } else if (
//             response.success === true &&
//             response.data.esign_status === true
//           ) {
//             setModStatusDPShame(3);
//           }
//         })
//         .catch((err) => {
//           ////console.log(err);
//         });
//     }
//   }, [props.activeTab]);

//   useEffect(() => {
//     if (props.activeTab === "2") {
//       checkModificationStatus({
//         client_code: client_code,
//         pan_no:
//           !isEmpty(profileDetails.userProfile[0]) &&
//           profileDetails.userProfile[0].PANGIR,
//         field_name: "ddpi_status",
//         esign_flag: "YES",
//       })
//         .then((response) => {
//           console.log('asdadasdasdasdad',response);
//           console.log('asdadasdasdasdad',response.success);
//           console.log('asdadasdasdasdad',response.data.esign_status);
//           if (
//             response.success === true &&
//             response.data.esign_status === false
//           ) {
//             setModStatusDDPI(1);
//           } else if (response.success === false) {
//             setModStatusDDPI(2);
//           } else if (
//             response.success === true &&
//             response.data.esign_status === true
//           ) {
//             setModStatusDDPI(3);
//           }
//           //props.sendData(modalMobile);
//         })
//         .catch((err) => {
//           ////console.log(err);
//         });
//     }
//   }, [props.activeTab]);
//   // console.log(dPscheme);
//   // console.log(modStatusDPShame);
//   const Catalogues = [
//     {
//       id: "Settlement Transactions",
//       name: "Settlement Transactions",
//     },
//     {
//       id: "Margin Pledge Transactions",
//       name: "Margin Pledge Transactions",
//     },
//     {
//       id: "Mutual Fund Transactions",
//       name: "Mutual Fund Transactions",
//     },
//     {
//       id: "Tendering Shares",
//       name: "Tendering Shares",
//     },
//   ];
//   const [list, setList] = useState(Catalogues);
//   // useEffect(() => {
//   //   setList(Catalogues);
//   // }, [list]);

//   const handleSelectAll = (e) => {
//     setIsCheckAll(!isCheckAll);
//     setIsCheck(list.map((li) => li.id));
//     if (isCheckAll) {
//       setIsCheck([]);
//     }
//   };

//   const handleClick = (e) => {
//     const { id, checked } = e.target;
//     setIsCheck([...isCheck, id]);

//     if (!checked) {
//       setIsCheck(isCheck.filter((item) => item !== id));
//       setIsCheckAll(false);
//     }
//   };

//   useEffect(() => {
//     if (isCheckAll === false && isCheck.length === 4) {
//       setIsCheckAll(true);
//     }
//   }, [isCheckAll, isCheck]);

//   const sameFunction = (event) => {
//     ////console.log(event.target.checked);
//     setChecked(event.target.checked);
//   };

//   const handleSelectScheme = (e) => {
//     setSelectedScheme(e.target.value);
//     setScheme(true);
//   };

//   const endFunction = (data) => {
//     //console.log("data")
//     setChecked(!checked);
//     setIsCheck([]);
//     setIsCheckAll(false);
//   };

//   // const eSignfun = () => {
//   //   const initialValues = {
//   //     ClientCode: ClientID.ClientId,
//   //     IsESign: "Yes",
//   //     IsType: "equity",
//   //     pan_number: profileDetails.userProfile[0].PANGIR,
//   //     full_address: `${profileDetails.userProfile[0].ADDRESS1},${profileDetails.userProfile[0].ADDRESS2},${profileDetails.userProfile[0].ADDRESS3},${profileDetails.userProfile[0].PINCODE},${profileDetails.userProfile[0].CITY},${profileDetails.userProfile[0].CSTATE},${profileDetails.userProfile[0].COUNTRY}`,
//   //     full_name: profileDetails.userProfile[0].FIBSACCT,
//   //     dpCode: profileDetails.userProfile[0].DPCODE,
//   //     email: profileDetails.userProfile[0].EMAIL,
//   //   };

//   //   DDPIESign(initialValues)
//   //     .then((response) => {
//   //       //console.log(response);
//   //       if (
//   //         response.success === true &&
//   //         response.message === "URL Generated Successfully."
//   //       ) {
//   //         //console.log(response);
//   //         setESignStart(true);
//   //         setESign(false);
//   //         window.open(`${response.data.URLL}`, "_blank");
//   //       } else {
//   //         setESign(false);
//   //         setESignComplected(true);
//   //       }
//   //     })
//   //     .catch((err) => {
//   //       ////console.log(err);
//   //     });
//   // };

//   // useEffect(() => {
//   //   if (eSignStart === true) {
//   //     const initialValues = {
//   //       ClientCode: ClientID.ClientId,
//   //       IsESign: "Yes",
//   //       IsType: "equity",
//   //       pan_number: profileDetails.userProfile[0].PANGIR,
//   //       full_address: `${profileDetails.userProfile[0].ADDRESS1},${profileDetails.userProfile[0].ADDRESS2},${profileDetails.userProfile[0].ADDRESS3},${profileDetails.userProfile[0].PINCODE},${profileDetails.userProfile[0].CITY},${profileDetails.userProfile[0].CSTATE},${profileDetails.userProfile[0].COUNTRY}`,
//   //       full_name: profileDetails.userProfile[0].FIBSACCT,
//   //       dpCode: profileDetails.userProfile[0].DPCODE,
//   //       email: profileDetails.userProfile[0].EMAIL,
//   //     };
//   //     let current = setInterval(
//   //       () =>
//   //         DDPIESign(initialValues)
//   //           .then((response) => {
//   //             //console.log(response);
//   //             if (
//   //               response.success === false &&
//   //               response.message === "ESign Already Done." &&
//   //               response.data === ""
//   //             ) {
//   //               setESignStart(false);
//   //               setESignComplected(true);
//   //               toast.success("ESign Completed Successfully", {
//   //                 autoClose: 1500,
//   //               });
//   //               history("/dashboard");
//   //             }
//   //           })
//   //           .catch((err) => {
//   //             ////console.log(err);
//   //           }),
//   //       10000
//   //     );
//   //     if (eSignStart === false) {
//   //       return () => {
//   //         clearInterval(current);
//   //       };
//   //     }
//   //   }
//   // }, [eSignStart]);

//   const eSignfun2 = () => {
//     const initialValues ={
//       ClientCode: client_code,
//       pan_number: profileDetails.userProfile[0].PANGIR,
//       IsESign: "Yes",
//       IsType : "equity",
//       esignType : "dp_scheme",
//       full_name : profileDetails.userProfile[0].FIBSACCT,
//       email : profileDetails.userProfile[0].EMAIL,
//     }

//     DDPIShameESign(initialValues)
//       .then((response) => {
//         console.log('5');
//         if (
//           response.success === true &&
//           response.message === "URL Generated Successfully."
//         ) {
//           setESignStart2(true);
//           setESign2(false);
//           window.open(`${response.data.URLL}`, "_blank");
//         } else {
//           setESign2(false);
//           setESignComplected2(true);
//           toast.error(`${response.message}`, {
//             autoClose: 1500,
//           });
//         }
//       })
//       .catch((err) => {
//         ////console.log(err);
//       });
//   };

//   useEffect(() => {
//     if (eSignStart2 === true) {
//       const initialValues ={
//         ClientCode: client_code,
//         pan_number: profileDetails.userProfile[0].PANGIR,
//         IsESign: "Yes",
//         IsType : "equity",
//         esignType : "dp_scheme",
//         full_name : profileDetails.userProfile[0].FIBSACCT,
//         email : profileDetails.userProfile[0].EMAIL,
//       }
//       let current = setInterval(
//         () =>
//           DDPIShameESign(initialValues)
//             .then((response) => {
//               console.log('6');
//               if (
//                 response.success === false &&
//                 response.message === "ESign Already Done."
//               ) {
//                 setESignStart2(false);
//                 setESignComplected2(true);
//                 clearInterval(current);

//                 if(dpSchemeName == 'FREE SCHEME'){
//                   var dp_ampunt = 50;
//                 }else if(dpSchemeName == '999 (Life Time)'){
//                   var dp_ampunt = 999;
//                 }else{
//                   var dp_ampunt = 333;
//                 }



//                 var mod_email_mobile = {
//                   client_name:profileDetails.userProfile[0].FIBSACCT,
//                   mod_type:'dpscheme',
//                   mod_mobile:profileDetails.userProfile[0].MOBILE,
//                   mod_email:profileDetails.userProfile[0].EMAIL,
//                   amount:dp_ampunt,
//                 };
//                 modMailSend(mod_email_mobile)
//                 .then((response) => {
//                   console.log('Mobile sms modification',response);
//                 })
//                 .catch((err) => {
//                   console.log(err);
//                 });
//                 console.log('55555555');
//                 // location.reload();

//               }
//             })
//             .catch((err) => {
//               ////console.log(err);
//             }),
//         10000
//       );
//       if (eSignStart2 === false) {
//         return () => {
//           clearInterval(current);
//         };
//       }
//     }
//   }, [eSignStart2]);

//   const shameListd = shameList.filter((el) => {
//     //console.log(el.toUpperCase());
//     const d = !isEmpty(profileDetails.userProfile)
//       ? profileDetails.userProfile[0].CFEESMAINCODE
//       : "";
//     //console.log(d);
//     return (
//       el !== "BSDA (Other)" && el !== "LICIPO SCHEME" && el.toUpperCase() !== d
//     );
//   });

//   const funCancel = () => {
//     setDPScheme(false);
//   }
//   const tog_signUpModals = () => {
//     setmodal_signUpModals(!modal_signUpModals);
//   };

// //console.log(profileDetails);
//   return (
//     <>
//       <Row>
//       <Modal
//         id="signupModals"
//         tabIndex="-1"
//         isOpen={modal_signUpModals}
//         backdropClassName="background-color4"
//         className="share-model-middle"
//         toggle={() => {
//           tog_signUpModals();
//         }}
//         centered
//         style={{ maxWidth: "350px" }}
//       >


//         <>
//         <ModalHeader
//           className="px-4 py-4"
//           toggle={() => {
//             tog_signUpModals();
//           }}
//         >
//           <div className="d-lg-block">
//             <p className="text-1 mb-0 text-color1">
//               SEBI has introduced a regulatory framework, Demat Debit and Pledge
//               Instruction (DDPI), in place of Power of Attorney (PoA) to
//               facilitate your stockbroker to meet pay-in obligations for the
//               settlement of trades you executed
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">Ease of Trading</p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               SEBI introduced DDPI to streamline the procedure and increase
//               transparency.
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               DDPI has curbed the process of collecting E-Dis from investors.
//               DDPI has replaced physical documentation with online process.
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               Why is it Safe & Secure?
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               DDPI will limit debiting shares without a sell trade from a
//               client’s account for secondary market transactions. It will
//               prevent the risk of breaking clauses, and pledging or re-pledging
//               of securities.
//             </p>
//             </div>
//         </ModalHeader> 
//         </>


//       </Modal>
//         <Col className="profile-main-head">
//           <div className="d-flex align-items-center justify-content-between mb-3">
//             <div>
//               <h4 className="text-9 text-color5 m-0">Additional Details</h4>
//               {/* <p className="text-color2 text-15 m-0">
//                 Manage your DDPI related details here.
//               </p> */}
//             </div>
//           </div>
//         </Col>
//       </Row>
//       <Row className="mt-2 mb-3">
//         <Col lg={2} xs={4}>
//           <Label htmlFor="nameInput" className="py-2 m-0 text-101 text-color5">
//             DDPI/POA
//           </Label>
//         </Col>
//               {profileDetails.userProfile[0].CPOWEROFATTORNEY}
//               {profileDetails.userProfile[0].CDDPIFLAG}
//               {modStatusDDPI}

//         {!isEmpty(profileDetails.userProfile) &&
//         profileDetails.userProfile[0].CPOWEROFATTORNEY === "Y" && profileDetails.userProfile[0].CDDPIFLAG === "Y" ? (
//           <Col lg={4} xs={6} className="additional-switch">
//             <div className="mt-2">
//               <h2 className="text-4 text-color1">
//                 DDPI / POA Status{" "}
//                 <span className="text-101 text-color6 ">Activated</span>
//               </h2>
//               <p className="text-17">Contact <a className="additional-status"
//                 href="mailto:support@bigul.co"
//                 target="_blank">Support</a> to change DDPI/ POA Status.</p>
//             </div>
//           </Col>
//         ) : !isEmpty(profileDetails.userProfile) &&
//         profileDetails.userProfile[0].CPOWEROFATTORNEY === "N" && profileDetails.userProfile[0].CDDPIFLAG === "N" && modStatusDDPI === 2 ? (
//           <Col lg={4} xs={6} className="additional-switch">
//             {!checked ? (
//               <div className="form-check form-switch">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   role="switch"
//                   id="flexSwitchCheckDefault"
//                   onChange={(event) => {sameFunction(event);
//                     setDPScheme(false)}}
//                   checked={checked}
//                 />
//               </div>
//             ) : (
//               <div className="mt-2">
//                 <h2 className="text-101 text-color1">
//                   Select atleast one option{" "}
//                   {/* <span className="text-4 text-color6 ">Activated</span> */}
//                 </h2>
//                 {/* <h4 className="additional-status">Change DDPI Status?</h4> */}
//               </div>
//             )}
//           </Col>
//         ) : !isEmpty(profileDetails.userProfile) &&
//         profileDetails.userProfile[0].CPOWEROFATTORNEY === "N" && profileDetails.userProfile[0].CDDPIFLAG === "N" && modStatusDDPI === 1 ? (
//           <Col lg={6} xs={6} className="">
//             <div className="d-flex align-items-center">
//               <p className="py-2 m-0 text-4 text-color10 mb-0">Pending</p>
//               <button
//                 type="button"
//                 className="esign-btn"
//                 onClick={() => eSignfun()}
//               >
//                 E Sign
//               </button>
//             </div>
//           </Col>
//         ) : !isEmpty(profileDetails.userProfile) &&
//         profileDetails.userProfile[0].CPOWEROFATTORNEY === "N" && profileDetails.userProfile[0].CDDPIFLAG === "N" && modStatusDDPI === 3 ? (
//           <Col lg={6} xs={6} className="">
//             <p className="py-2 m-0 text-4 text-color5 mb-0" style={{ color: "red" }}>
//               {/* Will be activated in 24 hours. */}
//               Processing
//             </p>
//           </Col>
//         ) : (
//           <></>
//         )}
//         {/* {modStatusDDPI === 1 ? (
//           <Col lg={6} xs={6} className="">
//             <p className="py-2 m-0 text-4 text-color5 mb-0">
//               Already we have one request for ddpi update
//             </p>
//           </Col>
//         ) : modStatusDDPI === 2 ? (
//           <Col lg={4} xs={6} className="additional-switch">
//             <div className="mt-2">
//               <h2 className="text-4 text-color1">
//                 DDPI Status{" "}
//                 <span className="text-4 text-color6 ">Activated</span>
//               </h2>
//               <h4 className="additional-status">Change DDPI Status?</h4>
//             </div>
//           </Col>
//         ) : (
//           <Col lg={4} xs={6} className="additional-switch">
//             {!checked ? (
//               <div className="form-check form-switch">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   role="switch"
//                   id="flexSwitchCheckDefault"
//                   onChange={(event) => sameFunction(event)}
//                   checked={checked}
//                 />
//               </div>
//             ) : (
//               <div className="mt-2">
//                 <h2 className="text-4 text-color1">
//                   DDPI Status{" "}
//                   <span className="text-4 text-color6 ">Activated</span>
//                 </h2>
//                 <h4 className="additional-status">Change DDPI Status?</h4>
//               </div>
//             )}
//           </Col>
//         )} */}
//       </Row>
//       {checked && (
//         <Row className="mb-3">
//           <Col lg={2} xs={0} >
//             {" "}
//           </Col>
//           <Col lg={5} xs={12}>
//             <Row className="mb-3">
//               <Col lg={8} xs={8} className="additional-transactions">
//                 <Label
//                   className="form-check-label text-4 text-color1"
//                   for="formCheck13"
//                 >
//                   All Transactions
//                 </Label>
//               </Col>
//               <Col lg={1} xs={1} className="additional-transactions">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   name="selectAll"
//                   id="selectAll"
//                   onChange={handleSelectAll}
//                   checked={isCheckAll}
//                   //checked
//                 />
//                 {/* {catalog} */}
//               </Col>
//             </Row>
//             {list.map(({ id, name }) => {
//               return (
//                 <>
//                   <Row className="mb-3">
//                     <Col lg={8} xs={8} className="additional-transactions">
//                       <Label
//                         className="form-check-label text-4 text-color1"
//                         for="formCheck13"
//                       >
//                         {name}
//                       </Label>
//                     </Col>
//                     <Col lg={1} xs={1} className="additional-transactions">
//                       <input
//                         key={id}
//                         type="checkbox"
//                         name={name}
//                         id={id}
//                         onChange={handleClick}
//                         checked={isCheck.includes(id)}
//                       />
//                     </Col>
//                   </Row>
//                 </>
//               );
//             })}
//             {/* <Row className="mb-3">
//               <Col lg={8} xs={8} className="additional-transactions">
//                 <Label
//                   className="form-check-label text-4 text-color1"
//                   for="formCheck13"
//                 >
//                   Settlement Transactions
//                 </Label>
//               </Col>
//               <Col lg={1} xs={1} className="additional-transactions">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="formCheck13"
//                   //checked
//                 />
//               </Col>
//             </Row> */}
//             {/* <Row className="mb-3">
//               <Col lg={8} xs={8} className="additional-transactions">
//                 <Label
//                   className="form-check-label text-4 text-color1"
//                   for="formCheck13"
//                 >
//                   Margin Pledge Transactions
//                 </Label>
//               </Col>
//               <Col lg={1} xs={1} className="additional-transactions">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="formCheck13"
//                   //checked
//                 />
//               </Col>
//             </Row>
//             <Row className="mb-3">
//               <Col lg={8} xs={8} className="additional-transactions">
//                 <Label
//                   className="form-check-label text-4 text-color1"
//                   for="formCheck13"
//                 >
//                   Mutual Fund Transactions
//                 </Label>
//               </Col>
//               <Col lg={1} xs={1} className="additional-transactions">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="formCheck13"
//                   //checked
//                 />
//               </Col>
//             </Row>
//             <Row className="mb-3">
//               <Col lg={8} xs={8} className="additional-transactions">
//                 <Label
//                   className="form-check-label text-4 text-color1"
//                   for="formCheck13"
//                 >
//                   Tendering Shares
//                 </Label>
//               </Col>
//               <Col lg={1} xs={1} className="additional-transactions">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="formCheck13"
//                   //checked
//                 />
//               </Col>
//             </Row> */}
//             {/* {isCheck.length === 0 && <p>Select one option</p>} */}
//             <a onClick={()=>setmodal_signUpModals(true)} className="btn-link text-primary d-inline-block d-lg-none mb-2">Terms & Conditions</a>
//             <Row>
//               <div className="d-flex mb-3">
//                 {/* <button
//                   className="btn btn-primary btn-sm text-5 me-3 additional-page-button-save"
//                   type="button"
//                   //id="password-addon"
//                   //onClick={() => setPasswordShow(!passwordShow)}
//                 >
//                   Save
//                 </button> */}
//                 {isCheck.length > 0 ? (
//                   <AdditionalDetailsModelIndex
//                     openState={openState}
//                     data={isCheck}
//                     data2={profileDetails}
//                     end={endFunction}
//                   />
//                 ) : (
//                   <button
//                     className="btn btn-primary  me-3 additional-page-button-save"
//                     type="button"
//                     disabled
//                     // onClick={() => tog_signUpModals()}
//                   >
//                     Save
//                   </button>
//                 )}
//                 <button
//                   className="me-2 additional-page-button-cancel"
//                   type="button"
//                   //id="password-addon"
//                   onClick={() => setChecked(!checked)}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </Row>
//           </Col>
//           <Col lg={4} xs={12}>
//             <div className="d-none d-lg-block">
//             <p className="text-1 mb-0 text-color1">
//               SEBI has introduced a regulatory framework, Demat Debit and Pledge
//               Instruction (DDPI), in place of Power of Attorney (PoA) to
//               facilitate your stockbroker to meet pay-in obligations for the
//               settlement of trades you executed
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">Ease of Trading</p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               SEBI introduced DDPI to streamline the procedure and increase
//               transparency.
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               DDPI has curbed the process of collecting E-Dis from investors.
//               DDPI has replaced physical documentation with online process.
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               Why is it Safe & Secure?
//             </p>
//             <p className="text-1 mb-0 mt-1 text-color1">
//               DDPI will limit debiting shares without a sell trade from a
//               client’s account for secondary market transactions. It will
//               prevent the risk of breaking clauses, and pledging or re-pledging
//               of securities.
//             </p>
//             </div>
//           </Col>
//         </Row>
//       )}
//       <Row className="mb-3">
//         <Col lg={2} xs={4}>
//           <Label htmlFor="nameInput" className="py-2 m-0 text-101 text-color5">
//             DP Scheme
//           </Label>
//         </Col>
//         {modStatusDPShame === 2 && !dPscheme ? (
//           <Col lg={10} xs={8} className="d-flex align-items-center">
//             <div className="d-flex align-items-center">
//               <h4 className="mb-0 text-4 text-color1 me-3">{!isEmpty(profileDetails.userProfile) ? profileDetails.userProfile[0].CFEESMAINCODE : ""}</h4>
//               <button type="button" className="text-11 text-color7 profile-edit-button1" onClick={() => {setDPScheme(true);
//               setChecked(false)} }>Edit</button>
//             </div>
//           </Col>
//           // <DPScheme />
//         ) : modStatusDPShame === 2 && dPscheme ? (<DPScheme funCancel={funCancel} />) : modStatusDPShame === 1 ? (
//           <Col lg={4}>
//             <div className="d-flex align-items-center">
//               <select
//                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                 id="nameInput"
//                 disabled
//                 style={{ width: "50%" }}
//                 onChange={(e) => {
//                   handleSelectScheme(e);
//                   // setSelectedScheme();
//                   // setScheme(true);
//                 }}
//               >
//                 <option>
//                   {!isEmpty(profileDetails.userProfile) &&
//                     profileDetails.userProfile[0].CFEESMAINCODE}
//                 </option>
//                 {/* <option key="999Scheme" value="999Scheme">
//               999 Scheme
//             </option> */}
//                 {/* {shameList.length > 0 &&
//                 shameList.map((el) => {
//                   return (
//                     <option key={el.toUpperCase()} value={el.toUpperCase()}>
//                       {el.toUpperCase()}
//                     </option>
//                   );
//                 })} */}
//               </select>
//               <button
//                 type="button"
//                 className="esign-btn"
//                 onClick={() => eSignfun2()}
//               >
//                 E-Sign
//               </button>
//             </div>
//           </Col>
//         ) : modStatusDPShame === 3 ? (
//           <Col lg={4}>
//             <p className="py-2 m-0 text-4 text-color10 mb-0">Pending</p>
//           </Col>
//         ) : (
//           <></>
//         )}
//       </Row>
//       {scheme && (
//         <Row className="mb-3">

//           <Col lg={2}> </Col>
//           <Col lg={5}>
//             <div className="d-flex mb-3">
//               {scheme ? (
//                 <DPSchemeModelIndex
//                   openState={openState}
//                   data={selectedScheme}
//                   data2={profileDetails}
//                 />
//               ) : (
//                 <button
//                   className="btn btn-primary me-3 additional-page-button-save"
//                   type="button"
//                   disabled
//                   //id="password-addon"
//                   //onClick={() => setPasswordShow(!passwordShow)}
//                 >
//                   Save
//                 </button>
//               )}
//               <button
//                 className="me-2 additional-page-button-cancel"
//                 type="button"
//                 //id="password-addon"
//                 onClick={() => setScheme(!scheme)}
//               >
//                 Cancel
//               </button>
//             </div>
//           </Col>
//         </Row>
//       )}
//     </>
//   );
// };

// export default AdditionalDetails;




import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  Modal,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  ModalHeader,
  ModalBody
} from "reactstrap";
import DPScheme from "./DPScheme";
import AdditionalDetailsModelIndex from "../../Components/CustomComponents/Models/additionalDetails/AdditionalDetailsModelIndex";
import DPSchemeModelIndex from "../../Components/CustomComponents/Models/DPScheme/DPSchemeModelIndex";
import { useSelector, useDispatch } from "react-redux";
import {
  SegmentActivationApiCall,
  checkModificationStatus,
  dpShameList,
  DDPIESign,
  DDPIShameESign,
  SymphonyWithdrawalBalance,
  modificationEsign,
  esignPayment,
} from "../../helpers/fakebackend_helper";
import { color } from "echarts";
import { MOD_EKYC_NEW, redirectApp } from "../../helpers/url_helper";
import axios from "axios";
import Encrypted from "../Dashboard/Encripted";
import Loader from "../../Components/Common/Loader";


const AdditionalDetails = (props) => {
  const [checked, setChecked] = useState(false);
  const client_code = localStorage.getItem("client_id");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [modification, setModification] = useState()
  const [switch1, setSwitch1] = useState(false)
  const [dPscheme, setDPScheme] = useState(false);
  const [esignName, setEsignName] = useState('')
  const [SchemeData, setSchemeData] = useState();
  const [EsignModal, setEsignModal] = useState(false);
  const [shouldContinueApi, setShouldContinueApi] = useState(false);
  const [balance, setBalance] = useState(false);
  const [deseble, setDeseble] = useState(false);
  const [ddpi, setDDpi] = useState(false);
  const [checkDdpi, setcheckDDpi] = useState(false);
  const symphony_token = localStorage.getItem("symphony_token");
  const client_token = localStorage.getItem("client_token");
  const [loading, setLoading] = useState(false)
  const { Balance } = props;

  useEffect(() => {
    if (ddpi) {
      setIsCheck([])
      setIsCheckAll(false)

    }
  }, [ddpi])

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails,
  }));
  const incomeammount = profileDetails?.userProfile[0]?.NINCOME
 
const openState = {
    CChange1: false,
    CChange2: true,
  };

  const funCancel = () => {
    setDPScheme(false);


  }

  const handleToggle = () => {
    setSwitch1(!switch1);
  };

  useEffect(() => {
    setShouldContinueApi(false)
  }, [props.activeTab === "2"])

  useEffect(() => {
    setcheckDDpi(false)
    setDDpi(false)
    setDPScheme(false)
    // setShouldContinueApi(false)

    // setModification(false)
    if (props.activeTab === "2") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "ddpi",

      })

        .then((response) => {
          if (response.status == true && response?.data?.modification?.length > 0) {
            setModification(response?.data?.modification[0])
          } else {
            setModification('')

          }


        })
        .catch((err) => {
          toast('something went wrong')

        });
    }
  }, [props.activeTab, checkDdpi, EsignModal]);



  useEffect(() => {
    // setShouldContinueApi(false)
    if (props.activeTab === "2") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "dpscheme",

      })

        .then((response) => {
          if (response.status == true && response?.data?.modification?.length > 0) {
            setSchemeData(response?.data?.modification[0])
          } else {
            setSchemeData('')

          }

        })
        .catch((err) => {
          setModification(false)


        });
    }
  }, [dPscheme, EsignModal]);
  useEffect(() => {
    setLoading(true)
    if (props.activeTab === "2") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "dpscheme",

      })

        .then((response) => {
          setLoading(false)

          if (response.status == true && response?.data?.modification?.length > 0) {
            setSchemeData(response?.data?.modification[0])
          } else {
            setSchemeData('')

          }

        })
        .catch((err) => {
          setLoading(false)

          setModification(false)


        });
    }
  }, [props.activeTab]);



  const Catalogues = [
    {
      id: "Settlement Transactions",
      name: "Settlement Transactions",
    },
    {
      id: "Margin Pledge Transactions",
      name: "Margin Pledge Transactions",
    },
    {
      id: "Mutual Fund Transactions",
      name: "Mutual Fund Transactions",
    },
    {
      id: "Tendering Shares",
      name: "Tendering Shares",
    },
  ];
  const [list] = useState(Catalogues);


  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setIsCheckAll(false);
    }
  };



  const endFunction = (data) => {

    setChecked(!checked);
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const handleEsign = (e) => {
    setEsignName(e)
    setEsignModal(true)
    setBalance(false)
  }

  const toggle = () => {
    setEsignModal(!EsignModal)
    // setShouldContinueApi(false)
  };


  useEffect(() => {

    let intervalId;

    const callApi = () => {
      checkModificationStatus({
        client_code: client_code,
        entry_type: esignName,

      })
        .then((response) => {
          if (response.status == true && response?.data?.modification?.length > 0) {
            if (response?.data?.modification[0]?.is_esign == 1) {
              if (esignName == 'dpscheme') {
                setSchemeData(response?.data?.modification[0])
              }
              else {
                setModification(response?.data?.modification[0])
              }
              setShouldContinueApi(false)
              setEsignModal(false)

            }

          } else {
            toast('something went wrong')
            // setShouldContinueApi(false)
          }
        })
        .catch((err) => {
          toast('something went wrong')

          // setShouldContinueApi(false)
        });


    };

    if (shouldContinueApi && props.activeTab === "2") {
      intervalId = setInterval(callApi, 2000); // Call API every 10 seconds
    }

    return () => {
      clearInterval(intervalId); // Cleanup on unmount or condition change
    };
  }, [shouldContinueApi, props.activeTab === "2"]);

  const handleEsignConfirm = () => {
    setBalance(false)
    if (isNaN(Balance)) {
      toast.error('Unable to fetch your ledger balance. Please try after some time.');
      return;
    }
    if (esignName == 'ddpi' ? Balance < 25 : Balance < JSON.parse(SchemeData?.new_value == 'FREESCHEME' ? 25 : JSON.parse(SchemeData?.new_value))) {
      setBalance(true)
    }
    else {
      setDeseble(true)
      modificationEsign({
        client_code: client_code,
        entry_type: esignName,
      })
        .then((response) => {
          if (response?.status) {
            setDeseble(false)
            window.open(`${response?.redirectUrl}`, "_blank");
            setShouldContinueApi(true)
            setEsignModal(false)

          }
          else {
            toast(response.message)
            setDeseble(false)
          }
        })
        .catch((err) => {
          setDeseble(false)

          toast('something went wrong')
        });
    }

  }
  const handlepay = () => {
    const calculateAmount = () => {
      if (Balance > 0) {
        return esignName === 'ddpi' ? (25 - Balance) : (JSON.parse(SchemeData?.new_value == 'FREESCHEME' ? 35 : SchemeData?.new_value) - Balance);
      } else {
        return esignName === 'ddpi' ? (Math.abs(Balance) + 25) : (Math.abs(Balance) + JSON.parse(SchemeData?.new_value == 'FREESCHEME' ? 35 : SchemeData?.new_value));
      }
    };
    const payload = {
      ClientCode: client_code,
      amount: calculateAmount(),
      redirectUrl: redirectApp,
      SubscriptionPlan: 'client_modification'

    }


    window.location.href = 'https://payment.bigul.co/v1/?enc=' + Encrypted([payload])
  }


  const handlechangeState = (value) => {
    setDDpi(value)
  }

  const handlechangeState1 = (value) => {
    setcheckDDpi(value)
    setEsignModal(true)
    setEsignName('ddpi')
  }

  const handlechangeScheme = (value) => {
    // setcheckDDpi(value)
    setEsignModal(value)
    setEsignName('dpscheme')
  }

  const handleCancel = async () => {
    setLoading(true)
    try {
      const payload = {
        client_code: client_code,
        entry_type: esignName
      }; // Your POST data here
      const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });
      if (response?.status == true) {

        setEsignModal(false)
        setBalance(false)
        setShouldContinueApi(false)
        setTimeout(() => {
          setLoading(false)
        }, 1000);

      } else {
        setTimeout(() => {
          setLoading(false)
        }, 1000);

        toast.error('something went wrong please try again')
      }
    } catch (error) {
      setLoading(false)

      toast.error('something went wrong please try again')
    }
  }
  if (loading) {
    return <Loader />
  }
  return (
    <>
      <Row>
        <Modal isOpen={EsignModal} toggle={toggle} >
          <ModalHeader toggle={toggle} >{balance ? 'Proceed to Pay' : 'Proceed to Esign'}</ModalHeader>
          <ModalBody>
            {
              balance ?
                <h4 className="text-9 text-color5 mt-3 text-center">
                  The amount available in your ledger balance is not not sufficient for this transaction.
                </h4>
                :
                <h4 className="text-9 text-color5 mt-3 text-center">
                  Please proceed to E-sign to confirm your request for modification.{esignName == 'ddpi' ? 'Rs. 35' :  (SchemeData?.new_value === "FREESCHEME" ? 'Rs. 35' : 'Rs. ' + SchemeData?.new_value)}{esignName == 'ddpi' ? '(including GST)' : "(inclusive of all charges)"} charge will be applicable for modification. The amount will be deducted from your ledger balance.

                </h4>
            }
            {/* <h4 className="text-9 text-color5 mt-3 text-center">
              Please proceed to E-sign to confirm your request for modification.{esignName == 'ddpi' ? 'Rs. 35' : 'Rs.' + SchemeData?.new_value}{esignName == 'ddpi' ? '(including GST)' : "(inclusive of all charges)"} charge will be applicable for modification. The amount will be deducted from your ledger balance.
            </h4> */}
          </ModalBody>
          <div className="d-flex justify-content-center">
            {
              balance ?
                <>
                  <button
                    type="button"
                    className="btn btn-primary modal-content-Button my-3"
                    onClick={handlepay}
                  >
                    {" "}
                    Proceed to pay
                  </button>
                  <button
                    type="button"
                    style={{
                      marginLeft: '10px',
                      backgroundColor: 'gray',
                      color: 'white',
                      border: '1px solid gray',
                      padding: '10px 20px',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                    className="btn btn-primary modal-content-Button my-3"
                    onClick={handleCancel}
                  >
                    Cancel Request
                  </button>
                </>
                :
                <>
                  <button
                    type="button"
                    style={{ marginLeft: '10px' }}
                    className="btn btn-primary modal-content-Button my-3"
                    onClick={handleEsignConfirm}
                    disabled={deseble}

                  >
                    {" "}
                    {deseble ? 'please wait...' : 'Proceed to eSign'}

                  </button>
                  <button
                    type="button"
                    style={{
                      marginLeft: '10px',
                      backgroundColor: 'gray',
                      color: 'white',
                      border: '1px solid gray',
                      padding: '10px 20px',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                    className="btn btn-primary modal-content-Button my-3"
                    onClick={handleCancel}
                  >
                    Cancel Request
                  </button>
                </>
            }

          </div>
        </Modal>
        <Col className="profile-main-head">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <h4 className="text-9 text-color5 m-0">Additional Details</h4>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/* <Col lg={2} xs={4}>
          <Label htmlFor="nameInput" className="py-2 m-0 text-101 text-color5">
            DDPI/POA
          </Label>
        </Col> */}

        {!isEmpty(profileDetails.userProfile) &&
          (profileDetails?.userProfile[0]?.CPOWEROFATTORNEY == "Y") || (profileDetails?.userProfile[0]?.CDDPIFLAG == "Y") ?
          <Col lg={4} xs={10} className="additional-switch">
            <div className="mt-2" >
              <h2 className="text-4 text-color1 mt-3 mb-2">
                DDPI / POA Status{" "}
                <span className="text-101 text-color6 ">{'Active'}</span>
              </h2>
              <p className="text-17">Contact <a className="additional-status"
                href="mailto:support@bigul.co"
                target="_blank">Support</a> to change DDPI/ POA Status.</p>
            </div>
          </Col>
          :

          modification?.is_esign == 0 ?
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Col lg={4} xs={6} className="additional-switch">
                <div className="mt-2">
                  <h2 className="text-4 text-color1 mt-3 mb-2">
                    DDPI / POA Status{" "}
                    <span style={{ cursor: 'pointer' }} onClick={() => handleEsign('ddpi')} className="text-101 text-color6 ">{'Esign pending'}</span>
                  </h2>
                  <p className="text-17">Contact <a className="additional-status"
                    href="mailto:support@bigul.co"
                    target="_blank">Support</a> to change DDPI/ POA Status.</p>
                </div>
              </Col>
              <button
                type="button"
                className="btn btn-primary modal-content-Button"

                onClick={() => {
                  if (incomeammount <= 0) {
                    toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
                    return
                  }
                  else {
                    handleEsign('ddpi')
                  }
                }

                }
              >
                {" "}
                Esign Pending
              </button>
            </div> :
            modification?.is_send_to_ld == 1 ?
              <Col lg={4} xs={10} className="additional-switch">
                <div className="mt-2">
                  <h2 className="text-4 text-color1 mt-3 mb-2">
                    DDPI / POA Status{" "}
                    <span className="text-101 text-color6 ">{'In progress'}</span>
                  </h2>
                  <p className="text-17">Contact <a className="additional-status"
                    href="mailto:support@bigul.co"
                    target="_blank">Support</a> to change DDPI/ POA Status.</p>
                </div>
              </Col>
              :
              modification?.is_esign == 1 ?
                <Col lg={4} xs={10} className="additional-switch">
                  <div className="mt-2">
                    <h2 className="text-4 text-color1 mt-3 mb-2">
                      DDPI / POA Status{" "}
                      <span className="text-101 text-color6 ">{'Pending'}</span>
                    </h2>
                    <p className="text-17">Contact <a className="additional-status"
                      href="mailto:support@bigul.co"
                      target="_blank">Support</a> to change DDPI/ POA Status.</p>
                  </div>
                </Col>
                :
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                  <Col lg={4} xs={10} className="additional-switch">
                    <div className="mt-2">
                      <h2 className="text-4 text-color1 mt-3 mb-2">
                        DDPI / POA Status{" "}
                        <span style={{ color: 'red' }} className="text-101 text-color6 ">{'Inactive'}</span>
                      </h2>
                      <p className="text-17">Contact <a className="additional-status"
                        href="mailto:support@bigul.co"
                        target="_blank">Support</a> to change DDPI/ POA Status.</p>
                    </div>
                  </Col>
                  <button
                    className="text-11 text-color7 profile-edit-button1"
                    onClick={() => {
                      setDDpi(true)
                    }}
                  >
                    Activate
                  </button>
                </div>

        }
      </Row>
      {
        ddpi &&
        <>
          <Row className="mb-3 mt-5">
            <Col lg={2} xs={0} >
              {" "}
            </Col>
            <Col lg={5} xs={12}>
              <Row className="mb-3">
                <Col lg={8} xs={8} className="additional-transactions">
                  <Label
                    className="form-check-label text-4 text-color1"
                    for="formCheck13"
                  >
                    All Transactions
                  </Label>
                </Col>
                <Col lg={1} xs={1} className="additional-transactions">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    onChange={handleSelectAll}
                    checked={isCheckAll}

                  />

                </Col>
              </Row>
              {list.map(({ id, name }) => {
                return (
                  <>
                    <Row className="mb-3">
                      <Col lg={8} xs={8} className="additional-transactions">
                        <Label
                          className="form-check-label text-4 text-color1"
                          for="formCheck13"
                        >
                          {name}
                        </Label>
                      </Col>
                      <Col lg={1} xs={1} className="additional-transactions">
                        <input
                          key={id}
                          type="checkbox"
                          name={name}
                          id={id}
                          onChange={handleClick}
                          checked={isCheck.includes(id)}
                        />
                      </Col>
                    </Row>
                  </>
                );
              })}

              <Row>
                <div className="d-flex mb-3">

                  {(
                    <AdditionalDetailsModelIndex
                      openState={openState}
                      data={isCheck}
                      data2={profileDetails}
                      end={endFunction}
                      handlechangeState={handlechangeState}
                      checkDdpi={checkDdpi}
                      handlechangeState1={handlechangeState1}
                      check={isCheck}
                    />
                  )}

                </div>
              </Row>
            </Col>
            <Col lg={4} xs={12}>
              <div className="d-none d-lg-block">
                <p className="text-1 mb-0 text-color1">
                  SEBI has introduced a regulatory framework, Demat Debit and Pledge
                  Instruction (DDPI), in place of Power of Attorney (PoA) to
                  facilitate your stockbroker to meet pay-in obligations for the
                  settlement of trades you executed
                </p>
                <p className="text-1 mb-0 mt-1 text-color1">Ease of Trading</p>
                <p className="text-1 mb-0 mt-1 text-color1">
                  SEBI introduced DDPI to streamline the procedure and increase
                  transparency.
                </p>
                <p className="text-1 mb-0 mt-1 text-color1">
                  DDPI has curbed the process of collecting E-Dis from investors.
                  DDPI has replaced physical documentation with online process.
                </p>
                <p className="text-1 mb-0 mt-1 text-color1">
                  Why is it Safe & Secure?
                </p>
                <p className="text-1 mb-0 mt-1 text-color1">
                  DDPI will limit debiting shares without a sell trade from a
                  client’s account for secondary market transactions. It will
                  prevent the risk of breaking clauses, and pledging or re-pledging
                  of securities.
                </p>
              </div>
            </Col>
          </Row>
        </>
      }
      <Row className='mt-5' style={{ display: 'flex', alignItems: 'center' }}>
        <Col lg={2} xs={4}>
          <Label htmlFor="nameInput" className="py-2 m-0 text-101 text-color5">
            DP SCHEME
          </Label>
        </Col>
        <Col lg={2} xs={4}>
          <Label htmlFor="nameInput" className="py-2 m-0 text-101 text-color5">
            {SchemeData?.new_value || profileDetails?.userProfile[0]?.CFEESMAINCODE}
          </Label>
        </Col>
        {

          SchemeData?.is_esign == 0 ?
          <Col lg={4} xs={4}>
          <button
          className="text-11 text-color7 profile-edit-button1 mb-1"
          onClick={() => handleEsign('dpscheme')}
        >
          Esign Pending
        </button>
        </Col>
            // <Col lg={4} xs={6}>
            //   <button
            //     type="button"
            //     className="btn btn-primary modal-content-Button"
            //     onClick={() => handleEsign('dpscheme')}
            //   >
            //     {" "}
            //     Esign Pending
            //   </button>
            // </Col>
            :
            SchemeData?.is_send_to_ld == 1 ?
              <Col lg={2} xs={4}>
                <div style={{ cursor: 'pointer', color: 'green' }} >
                  <Label htmlFor="nameInput" className="py-2 m-0 text-101">
                    In process
                  </Label>
                </div>
              </Col>
              :
              SchemeData?.is_esign == 1 ?
                <Col lg={2} xs={4}>
                  {/* <div style={{ cursor: 'pointer', color: 'yellow' }} > */}
                  <p className="text-5 text-color10 m-0"

                  >Pending</p>
                  {/* </div> */}
                </Col>
                :

                <Col lg={2} xs={4}>

                  <button
                    className="text-11 text-color7 profile-edit-button1 mb-1"
                    onClick={() => {
                      if (incomeammount <= 0) {
                        toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
                        return
                      }
                      else {
                        setDPScheme(true)
                      }

                    }}
                  >
                    Edit
                  </button>
                </Col>
        }

        {
          dPscheme &&
          <DPScheme funCancel={funCancel} handlechangeScheme={handlechangeScheme} />

        }

      </Row>
    </>



  );
};

export default AdditionalDetails;

